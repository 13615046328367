import { FileDownload, InsertDriveFile, Search } from '@mui/icons-material';
import { CircularProgress, IconButton, Link, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { documents as documentsApi } from '../../api';
import routes from '../../routes';
import { sxHover } from '../../style/theme';
import { Document, DocumentType, ExpandIconMenuProps, SearchDocument, WorkOrderStatus } from '../../types';
import Analytics from '../../utils/analytics';
import { formatBytes } from '../../utils/helper';
import BaseDrawer from '../BaseDrawer';
import DashboardItemDetails from '../Dashboard/DashboardItemDetails';
import SearchInput from '../FormControls/SearchInput';
import IconTextPlaceholder from '../IconTextPlaceholder';
import StatusChip from '../StatusChip';

const style = {
  noResults: {
    marginTop: 2,
    padding: 5,
    textAlign: 'center',
    height: 'auto',
  },
  header: {
    marginTop: 3,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 1,
  },
  shipmentLink: {
    marginTop: '3px',
    textDecoration: 'underline',
  },
  inputContainer: {
    width: 290,
    marginTop: -3,
  },
};

export function SearchDocuments({ anchorElement, setAnchor }: ExpandIconMenuProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [isLoading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchDocument | null>(null);
  const [searchText, setSearchText] = useState('');
  const prevSearchText = useRef(searchText);
  const [downloadingIds, setDownloadingIds] = useState<string[]>([]);
  const { state, pathname }: { state: any; pathname: string } = useLocation();
  const searchRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isSearchValid(state?.serialNumber)) {
      setSearchText(state?.serialNumber);
      searchRef.current?.setText(state?.serialNumber, true);
      // clean state after processing value
      navigate(pathname, { state: null, replace: true });
    }
  }, [state, navigate, pathname]);

  useEffect(() => {
    if (prevSearchText.current !== searchText) {
      setAnchor(isSearchValid(searchText) ? document.documentElement : null);
      prevSearchText.current = searchText;
    } else if (!anchorElement) {
      searchRef.current?.setText('');
      prevSearchText.current = '';
    }
  }, [searchText, setAnchor, anchorElement]);

  const searchDocuments = async (keyword: string) => {
    setSearchText(keyword);
    setSearchResult(null);
    if (isSearchValid(keyword)) {
      try {
        setLoading(true);
        Analytics.sendEvent('search_serial_number', { serial_number: keyword });
        const { data } = await documentsApi.search(keyword);
        setSearchResult(data);
      } catch (err: any) {
        if (err.response.status == StatusCodes.NOT_FOUND) {
          setSearchResult({ documents: [], workOrderDetails: null });
        } else {
          throw err;
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const isSearchValid = (keyword: string) => keyword && keyword.length > 2;

  const isActive = () => !!anchorElement;

  const onClearSearchPress = () => {
    setSearchText('');
    setSearchResult(null);
  };

  const onDownloadPress = async (documentId: string) => {
    setDownloadingIds((prev) => [...prev, documentId]);
    try {
      await documentsApi.download(documentId, DocumentType.Document);
    } finally {
      setDownloadingIds((prev) => prev.filter((id) => id !== documentId));
    }
  };

  const isDownloading = (id: string) => downloadingIds.includes(id);

  const getStatusChipColor = () => {
    switch (searchResult?.workOrderDetails?.woStatus) {
      case WorkOrderStatus.Open:
        return 'active';
      case WorkOrderStatus.ReadyToShip:
        return 'success';
      default:
        return 'disabled';
    }
  };

  const onShipmentClick = () => {
    Analytics.sendEvent('click_shipment', { shipment_number: searchResult?.workOrderDetails?.shipmentNumber });
    onClearSearchPress();
  };

  const renderWorkOrderDetails = () =>
    searchResult?.workOrderDetails && (
      <Box sx={style.header}>
        <DashboardItemDetails
          data={[
            {
              title: t('common:status'),
              value: (
                <StatusChip
                  color={getStatusChipColor()}
                  size="large"
                  text={t(`common:workOrder.status.${searchResult.workOrderDetails.woStatus}`)}
                />
              ),
              gridSize: 4,
            },
            {
              title: t('sales-orders:search.shipment'),
              value: (
                <Link
                  sx={style.shipmentLink}
                  href={routes.salesOrder.path.replace(':id', searchResult.workOrderDetails.salesOrderId)}
                  variant="link"
                  onClick={onShipmentClick}
                >
                  {searchResult.workOrderDetails.shipmentNumber}
                </Link>
              ),
              gridSize: 8,
            },
          ]}
        />
      </Box>
    );

  const renderInputSearch = () => (
    <Box sx={style.inputContainer}>
      <SearchInput
        setActiveColor={isActive()}
        ref={searchRef}
        onClearText={onClearSearchPress}
        placeholder={t('sales-orders:search.action')}
        onChangeText={searchDocuments}
      />
    </Box>
  );

  const renderNoSearch = () => (
    <IconTextPlaceholder
      containerSx={style.noResults}
      title={t('sales-orders:search.findTitle')}
      description={t('sales-orders:search.findDescription')}
      icon={Search}
    />
  );

  const renderEmptyResults = () => (
    <Stack>
      {renderWorkOrderDetails()}
      <IconTextPlaceholder
        containerSx={style.noResults}
        title={
          <Trans
            i18nKey="sales-orders:search.emptyTitle"
            values={{ serialNumber: searchText }}
            components={[
              <Typography display="inline" variant="h3" />,
              <Typography display="inline" fontWeight="700" variant="h3" />,
            ]}
          />
        }
        description={!searchResult?.workOrderDetails ? t('sales-orders:search.emptyDescription') : ''}
      />
    </Stack>
  );

  const renderDocumentItem = ({ documentId, name, type, size }: Document) => (
    <ListItem sx={style.listItem} key={documentId} divider>
      <Stack direction="row" spacing={2}>
        <InsertDriveFile htmlColor={palette.grey[300]} />
        <Stack spacing={0.5}>
          <Typography fontWeight="400" variant="paragraphSmall">
            {`${name}.${type}`}
          </Typography>
          <Typography color={palette.grey[400]} variant="caption">
            {formatBytes(size)}
          </Typography>
        </Stack>
      </Stack>
      <IconButton disabled={isDownloading(documentId)} onClick={() => onDownloadPress(documentId)}>
        {isDownloading(documentId) ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <FileDownload sx={sxHover} htmlColor={palette.grey[400]} />
        )}
      </IconButton>
    </ListItem>
  );

  const renderResults = () => (
    <Stack spacing={3}>
      {renderWorkOrderDetails()}
      <Box>
        <Trans
          i18nKey="sales-orders:search.found"
          values={{ serialNumber: searchText, count: searchResult?.documents?.length }}
          components={[
            <Typography display="inline" fontWeight="400" variant="paragraphSmall" />,
            <Typography display="inline" fontWeight="700" variant="paragraphSmall" />,
          ]}
        />
      </Box>
      <List>{searchResult?.documents.map(renderDocumentItem)}</List>
    </Stack>
  );

  const renderLoading = () => (
    <Box sx={style.noResults} display="flex" justifyContent="center">
      <CircularProgress size={50} />
    </Box>
  );

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    } else if (!searchResult) {
      return renderNoSearch();
    } else if (!searchResult.documents || searchResult.documents.length === 0) {
      return renderEmptyResults();
    } else if (searchResult.documents.length > 0) {
      return renderResults();
    }
    return <></>;
  };

  return (
    <Box id="search-documents" flexGrow={0}>
      {renderInputSearch()}
      <BaseDrawer isOpen={isActive()} onClose={onClearSearchPress} title={t('sales-orders:search.title')}>
        {renderContent()}
      </BaseDrawer>
    </Box>
  );
}
