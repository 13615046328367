import { countries as countriesApi } from '../api';
import { Countries } from '../types';

const KEY = 'countries';

export async function getCountries(): Promise<Countries['countries']> {
  const currentCountries = sessionStorage.getItem(KEY);
  if (!currentCountries) {
    const result = await countriesApi.getCountries();
    sessionStorage.setItem(KEY, JSON.stringify(result.data.countries));
    return result.data.countries;
  }

  return JSON.parse(currentCountries);
}

export async function getCountry(code: string) {
  const countries = await getCountries();
  return countries.find((c) => c.code === code);
}

export async function getCountryStates(countryCode: string) {
  const countries = await getCountries();
  return countries.find((c) => c.code === countryCode)?.states ?? [];
}
