import React from 'react';
import { Navigate, RouteProps, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useIsMounted from '../../hooks/useIsMounted';
import routes from '../../routes';

const RequireAuth = ({ children }: RouteProps) => {
  const { user } = useAuth();
  const location = useLocation();
  const isMounted = useIsMounted();

  return user ? (
    <>{children}</>
  ) : (
    <Navigate to={routes.login.path} replace state={{ path: !isMounted() ? location.pathname : null }} />
  );
};

export default RequireAuth;
