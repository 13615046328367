import { Add, Edit } from '@mui/icons-material';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreationContext } from '../../contexts/CreationContext';
import { Address, CreationForm, CustomerAccount, Shipping, ShippingInstructions, UserPlants } from '../../types';
import { getAddressInitialValues } from '../../utils/form';
import { getCurrentLanguage } from '../../utils/helper';
import Input from '../FormControls/Input';
import AddressCard from './Address/AddressCard';
import NewAddressModal from './Address/NewAddressModal';
import SelectAddressModal from './Address/SelectAddressModal';

const style = {
  instructionsContainer: {
    marginTop: '40px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '25px',
  },
};

type Props = {
  userPlants: UserPlants['plants'];
  instructions: ShippingInstructions['shippingInstructions'];
};

const CreationStep2 = ({ userPlants, instructions }: Props) => {
  const { t } = useTranslation();
  const context = useContext(CreationContext);
  const { getValues, setValue } = useFormContext<CreationForm>();
  const watchCustomerAccountId = useWatch<CreationForm>({ name: 'customerAccountId' });
  const [shipping, setShipping] = useState<Shipping>(getValues('shipping'));
  const [showSelectAddress, setShowSelectAddress] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [customerAccount, setCustomerAccount] = useState<CustomerAccount | null>(null);

  useEffect(() => {
    const customerAccount = userPlants.flatMap((c) => c.customerAccounts).find((a) => a.id === watchCustomerAccountId);
    if (customerAccount) {
      setCustomerAccount(customerAccount);
      let currentShipping = getValues('shipping');
      // validate if customer account has changed with a selected address
      if (currentShipping.addressId) {
        const sameAccountAddress = customerAccount.addresses.find((a) => a.addressId === currentShipping.addressId);
        currentShipping = sameAccountAddress ? currentShipping : { ...currentShipping, ...getAddressInitialValues() };
      }
      // if address not yet set
      if (!currentShipping.consigneeName) {
        const address = customerAccount.addresses.find((a) => a.isDefault) ?? customerAccount?.addresses[0];
        setShipping((prev) => ({ ...prev, ...address }));
      }
    }
  }, [userPlants, watchCustomerAccountId, getValues]);

  useEffect(() => {
    setValue('shipping', shipping);
  }, [shipping, setValue]);

  const onNewAddressClick = () => {
    setShowNewAddress(true);
  };

  const onSelectAddressClick = () => {
    setShowSelectAddress(true);
  };

  const onSelectAddressConfirm = (addressId: string) => {
    const address = customerAccount?.addresses.find((a) => a.addressId === addressId);
    if (address) {
      setShipping((prev) => ({ ...prev, ...address, saveAddress: false }));
    }
    setShowSelectAddress(false);
  };

  const onNewAddressConfirm = (address: Address, saveAddress: boolean) => {
    setShipping((prev) => ({ ...prev, ...address, saveAddress, addressId: undefined }));
    setShowNewAddress(false);
  };

  const updateInstruction = (instructionId: string, ev: React.ChangeEvent<HTMLInputElement>) => {
    setShipping((prev) => {
      const instructionIds = [...prev.instructionIds];
      if (ev.target.checked) {
        instructionIds.push(instructionId);
      } else {
        instructionIds.splice(instructionIds.indexOf(instructionId), 1);
      }
      return { ...prev, instructionIds };
    });
  };

  const onNoteChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShipping((prev) => ({ ...prev, shippingNote: event.target.value }));
  };

  const renderShippingInstructions = () => {
    return (
      <Stack sx={style.instructionsContainer} spacing="15px">
        <Box>
          <Typography variant="h2">{t('form:step2.shippingInstructions.title')}</Typography>
          <Divider sx={style.divider} />
        </Box>
        <Typography variant="label">{t('form:step2.shippingInstructions.instructions')}</Typography>
        <Box>
          {instructions.length === 0 ? (
            <Typography variant="paragraphSmall">{t('form:noOptions')}</Typography>
          ) : (
            instructions.map((i) => (
              <FormControlLabel
                key={i.id}
                control={
                  <Checkbox
                    onChange={(ev) => updateInstruction(i.id, ev)}
                    checked={shipping.instructionIds.includes(i.id)}
                  />
                }
                label={i.label[getCurrentLanguage()]}
              />
            ))
          )}
        </Box>
        <Input
          variant="outlinedWhite"
          value={shipping.shippingNote}
          onChange={onNoteChanged}
          label={t('form:step2.shippingInstructions.notes')}
        />
      </Stack>
    );
  };

  return (
    <Box>
      <Grid container spacing={1.5} display="flex" flexDirection="row" justifyContent="space-between">
        <Grid item xs={12} sm={9}>
          {!!shipping && <AddressCard address={shipping} saveAddress={shipping.saveAddress} />}
        </Grid>
        <Grid item xs={12} sm={3}>
          {!context.quotation && (
            <Stack spacing={1.2}>
              {customerAccount && customerAccount.addresses.length > 0 && (
                <Button onClick={onSelectAddressClick} variant="containedSmall">
                  {t('form:step2.selectAddress')}
                </Button>
              )}
              <Button
                onClick={onNewAddressClick}
                startIcon={shipping?.addressId ? <Add /> : <Edit />}
                variant="outlined"
              >
                {shipping?.addressId ? t('form:step2.newAddress') : t('form:step2.editAddress')}
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      {context.type === 'SalesOrder' && renderShippingInstructions()}
      <SelectAddressModal
        currentAddressId={shipping?.addressId}
        addresses={customerAccount?.addresses ?? []}
        isVisible={showSelectAddress}
        onCancel={() => setShowSelectAddress(false)}
        onConfirm={onSelectAddressConfirm}
      />
      <NewAddressModal
        currentAddress={shipping?.addressId ? null : shipping}
        isVisible={showNewAddress}
        onCancel={() => setShowNewAddress(false)}
        onConfirm={onNewAddressConfirm}
      />
    </Box>
  );
};
export default CreationStep2;
