import type {} from '@mui/lab/themeAugmentation';
import { createTheme, LinkProps as MuiLinkProps, Theme, TypographyProps } from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import LinkBehavior from '../components/LinkBehavior';
import { Domain } from '../types';
import { getDomain } from '../utils/helper';
import { typography } from './typography';

export const colors = {
  primary: '#00447C',
  secondary: '#0F1B25',
  grey1: '#F3F5F7',
  grey2: '#D8DCE0',
  grey3: '#979FA8',
  grey4: '#6D737B',
  grey5: '#42474D',
  grey6: '#0F1B25',
  ...(getDomain() === Domain.firetrol && {
    primary: '#8C1D23',
    secondary: '#252525',
    grey1: '#F2F2F2',
    grey2: '#D8D8D8',
    grey3: '#999999',
    grey4: '#6F6F6F',
    grey5: '#454545',
    grey6: '#252525',
  }),
  error: '#F13540',
  warning: '#E7731D',
  link: '#2196F3',
};

export const sxHover = {
  ':hover': {
    color: colors.primary,
  },
};

const disabledField = {
  '&.Mui-disabled fieldset, & .Mui-disabled fieldset': {
    opacity: '0.4',
  },
};

export default createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.error },
    warning: { main: colors.warning },
    grey: {
      100: colors.grey1,
      200: colors.grey2,
      300: colors.grey3,
      400: colors.grey4,
      500: colors.grey5,
      600: colors.grey6,
    },
  },
  typography,
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: colors.grey5, //set default color here because 'allVariants' in typography doesn't affect custom variants
          ...(ownerState?.variant === 'link' && {
            color: colors.link + ' !important',
            ':hover': {
              textDecoration: 'underline',
            },
          }),
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
      },
      styleOverrides: {
        tooltip: {
          ...typography.paragraphSmall,
          backgroundColor: colors.grey6,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderTop: '1px solid ' + colors.grey2,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === 'body' && {
            ...typography.paragraphSmall,
            fontWeight: 400,
            verticalAlign: 'top',
          }),
          ...(ownerState?.variant === 'head' && {
            ...typography.menuItem,
          }),
          color: colors.grey5,
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          color: colors.grey3,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          color: colors.grey3,
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        componentsProps: {
          typography: {
            ...(typography.paragraphSmall as TypographyProps),
            color: colors.grey5,
          },
        },
      },
      styleOverrides: {
        root: {
          marginRight: 30,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          marginBottom: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
          whiteSpace: 'normal',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& > div': {
            height: ownerState?.multiline ? 'auto' : '48px',
          },
          '& input': {
            paddingTop: ownerState?.multiline ? 'auto' : '13px',
            WebkitAppearance: 'none',
          },
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
          ...disabledField,
        }),
      },
      defaultProps: {
        InputProps: {
          sx: {
            '& ::placeholder': {
              opacity: '0.8 !important',
            },
          },
          style: {
            backgroundColor: colors.grey1,
            borderRadius: 5,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          height: 56,
          ...typography.buttonLarge,
          borderRadius: 10,
          '& .MuiTypography-root': {
            color: 'inherit',
          },
          ...(ownerState.variant === 'containedSmall' && {
            height: 'auto',
            minHeight: '42px',
            borderRadius: '5px',
            backgroundColor: colors.primary,
            color: theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            ...typography.buttonMedium,
          }),
          ...(ownerState.variant === 'outlined' && {
            height: 'auto',
            minHeight: '42px',
            borderRadius: 5,
            borderColor: colors.grey5,
            color: colors.grey5,
            ...typography.buttonMedium,
          }),
          ...(ownerState.variant === 'text' && {
            height: 'auto',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
            color: colors.grey5,
            ...typography.buttonMedium,
          }),
        }),
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: ({ ownerState }) => ({
          color: ownerState?.variant === 'contained' ? colors.primary : colors.grey5,
          '& span': {
            height: '20px !important',
            width: '20px !important',
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        textTransform: 'uppercase',
        component: LinkBehavior,
      } as MuiLinkProps,
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
        sx: {
          '&:hover': {
            backgroundColor: colors.grey1,
          },
        },
      },
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ...disabledField,
        },
      },
      defaultProps: {
        componentsProps: {
          paper: {
            sx: (theme) => selectPaperSx(theme),
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: (theme) => selectPaperSx(theme),
          },
        },
      },
      styleOverrides: {
        root: {
          ...disabledField,
        },
        select: {
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 7,
        },
      } as any,
    },
  },
});

const selectPaperSx = ({ palette }: Theme) => ({
  '& ul, .MuiAutocomplete-noOptions': {
    border: '1px solid ' + colors.grey2,
    borderRadius: 2,
  },
  '& li': {
    marginRight: '4px',
    borderLeft: '4px solid ' + palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    width: 'auto',
  },
  "&& .Mui-selected, && li[aria-selected='true']": {
    backgroundColor: 'transparent',
    borderLeft: '3px solid ' + colors.primary,
    fontWeight: 700,
    color: colors.primary,
  },
  '& li:hover': {
    backgroundColor: colors.grey1,
    cursor: 'pointer',
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedSmall: true;
  }
}
