import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography = {
  fontFamily: 'Barlow',
  caption: {
    fontWeight: 400,
    fontSize: 14,
  },
  h1: {
    fontSize: 32,
    fontWeight: 400,
  },
  h2: {
    fontSize: 24,
    fontWeight: 400,
  },
  h3: {
    fontSize: 20,
    fontWeight: 500,
  },
  h6: {
    fontWeight: 500,
  },
  paragraphBig: {
    fontSize: 18,
    fontWeight: 400,
  },
  paragraphBigBold: {
    fontSize: 18,
    fontWeight: 700,
  },
  paragraphSmall: {
    fontSize: 16,
    fontWeight: 500,
  },
  paragraphSmallBold: {
    fontSize: 16,
    fontWeight: 700,
  },
  buttonLarge: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 2,
  },
  buttonMedium: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 2,
  },
  buttonSmall: {
    fontSize: 13,
    fontWeight: 700,
  },
  menuItem: {
    fontSize: 14,
    fontWeight: 600,
  },
  label: {
    fontSize: 20,
    fontWeight: 400,
  },
  link: {
    letterSpacing: 0,
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 500,
  },
  inputError: {
    fontWeight: 400,
    fontSize: 14,
    color: '#F13540 !important',
    position: 'relative',
    top: '7px',
  },
} as ExtendedTypographyOptions;

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuItem: true;
    paragraphBig: true;
    paragraphBigBold: true;
    paragraphSmall: true;
    paragraphSmallBold: true;
    buttonLarge: true;
    buttonMedium: true;
    buttonSmall: true;
    label: true;
    link: true;
    inputError: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  menuItem: React.CSSProperties;
  paragraphBig: React.CSSProperties;
  paragraphBigBold: React.CSSProperties;
  paragraphSmall: React.CSSProperties;
  paragraphSmallBold: React.CSSProperties;
  buttonLarge: React.CSSProperties;
  buttonMedium: React.CSSProperties;
  buttonSmall: React.CSSProperties;
  label: React.CSSProperties;
  link: React.CSSProperties;
  inputError: React.CSSProperties;
}
