import { Stack } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Input from '../Input';
import InputErrorMessage from '../InputErrorMessage';

type Props = {
  name: string;
  control: Control<any, any>;
  hideError?: boolean;
  min?: number;
  allowUndefinedNumber?: boolean;
} & Omit<React.ComponentProps<typeof Input>, 'value'>;

const InputHookForm = ({
  name,
  control,
  variant = 'outlinedWhite',
  hideError,
  min,
  allowUndefinedNumber,
  ...props
}: Props) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value }, formState: { errors } }) => (
      <Stack id={name} width="100%">
        <Input
          textAlign={props.type === 'number' ? 'right' : 'left'}
          {...props}
          onChange={(e) => {
            let val: string | number | undefined = e.target.value;
            if (props.type === 'number') {
              if (!val && allowUndefinedNumber) {
                val = undefined;
              } else {
                val = min !== undefined ? Math.max(min, +val) : +val;
              }
            }
            onChange(val);
          }}
          value={value ?? ''}
          variant={variant}
        />
        <InputErrorMessage hideError={hideError} errors={errors} name={name} />
      </Stack>
    )}
  />
);

export default InputHookForm;
