import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import { Box, ButtonBase, Container, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../../style/theme';
import { typography } from '../../../style/typography';

const style = {
  button: {
    zIndex: 1,
    minHeight: '95px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
};

type Props = {
  isOpen: boolean;
  sx?: SxProps<Theme>;
  onClick: () => void;
};

const SearchProductsTitle = ({ isOpen, sx, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonBase sx={{ ...style.button, ...sx }} onClick={onClick}>
      <Container>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Search sx={{ ...typography.h1 }} htmlColor={theme.palette.grey[500]} />
            <Typography variant="h2">{t('form:step3.search.title')}</Typography>
          </Stack>
          {isOpen ? (
            <KeyboardArrowDown sx={{ ...typography.h1 }} htmlColor={theme.palette.grey[500]} />
          ) : (
            <KeyboardArrowUp sx={{ ...typography.h1 }} htmlColor={theme.palette.grey[500]} />
          )}
        </Box>
      </Container>
    </ButtonBase>
  );
};
export default SearchProductsTitle;
