import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { StatusCodes } from 'http-status-codes';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { account as accountApi } from '../api';
import { UnauthenticatedBox } from '../components';
import InputHookForm from '../components/FormControls/HookForm/InputHookForm';
import routes from '../routes';
import { EMAIL_REGEX } from '../utils/constants';

const style = {
  title: {
    marginTop: 5,
    marginBottom: 2,
  },
  buttonContainer: {
    paddingTop: 5,
  },
  cancelLink: {
    alignSelf: 'flex-start',
  },
};

export function ForgotPassword() {
  const { t } = useTranslation();
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState('');

  const validationEmail = Yup.object({
    email: Yup.string()
      .required(t('form:error.fieldRequired'))
      .matches(EMAIL_REGEX, { message: t('common:user.emailInvalid') }),
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(validationEmail),
  });

  const onForgotPasswordPress = async ({ email }: { email: string }) => {
    try {
      await accountApi.forgotPassword(email);
      setRequestSent(true);
    } catch (err: any) {
      if (err.response.status == StatusCodes.NOT_FOUND || err.response.status == StatusCodes.UNAUTHORIZED) {
        setRequestSent(true);
      } else {
        setError(t('common:unknownError'));
      }
    }
  };

  return (
    <UnauthenticatedBox>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onForgotPasswordPress)}>
          <Stack sx={style.title} spacing={2}>
            <Typography variant="h1">{t('auth:forgotPassword.title')}</Typography>
            <Typography variant="paragraphSmall">
              {requestSent ? t('auth:forgotPassword.confirmation') : t('auth:forgotPassword.description')}
            </Typography>
          </Stack>
          {!requestSent ? (
            <>
              <InputHookForm variant="outlined" label={`${t('common:user.email')}*`} name={'email'} control={control} />
              <Stack sx={style.buttonContainer} spacing={3}>
                {error && <Typography variant="inputError">{error}</Typography>}
                <LoadingButton
                  loading={formState.isSubmitting}
                  type="submit"
                  disabled={formState.isSubmitting}
                  variant="contained"
                >
                  {t('auth:forgotPassword.action')}
                </LoadingButton>
                <Button sx={style.cancelLink} href={routes.login.path} variant="outlined">
                  {t('common:cancel')}
                </Button>
              </Stack>
            </>
          ) : (
            <Button sx={style.cancelLink} href={routes.login.path} variant="outlined">
              {t('auth:login.back')}
            </Button>
          )}
        </form>
      </Container>
    </UnauthenticatedBox>
  );
}
