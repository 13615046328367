import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import useDebounce from '../../hooks/useDebounce';
import theme from '../../style/theme';
import { SelectOption } from '../../types';
import Input from './Input';

const style = {
  optionList: {
    maxHeight: '200px',
    overflow: 'auto',
  },
  noOptionsText: {
    padding: '10px',
  },
  disableOption: {
    color: theme.palette.grey[300],
    '&:hover': {
      cursor: 'default !important',
    },
  },
  clearButton: {
    height: '48px',
  },
};

type BaseProps = {
  data: SelectOption[];
  onOptionClick: (option: SelectOption | null) => void;
  fetchData: (keyword: string, offset: number) => Promise<void>;
  hasMoreItems: boolean;
} & Omit<React.ComponentProps<typeof Input>, 'value' | 'onSelect'>;

type SelectionProps = {
  mode: 'selection';
  currentOption: SelectOption | null;
  selectedValues?: undefined; // only for mode click only
} & BaseProps;

type ClickOnlyProps = {
  mode: 'clickOnly';
  currentOption?: undefined; // only for mode selection
  selectedValues?: string[];
} & BaseProps;

const SearchSelectInfiniteScroll = ({
  data,
  onOptionClick,
  fetchData,
  hasMoreItems,
  selectedValues,
  mode,
  currentOption,
  ...props
}: SelectionProps | ClickOnlyProps) => {
  const { t } = useTranslation();
  const { components, palette } = useTheme();
  const [inputValue, setInputValue] = useState(mode === 'selection' && currentOption ? currentOption.label : '');
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const debouncedSearchText = useDebounce(inputValue, 1000);
  const hasFirstManualOpen = useRef(false);
  const containerRef = useRef<null | HTMLElement>(null);
  const stableProps = useRef({ fetchData, isLoading: false, offset: 0 });

  useEffect(() => {
    if (mode === 'selection') {
      hasFirstManualOpen.current = false;
      stableProps.current.offset = 0;
      setInputValue(currentOption?.label ?? '');
    }
  }, [currentOption, mode]);

  const loadMore = async (searchText: string) => {
    const { fetchData, offset } = stableProps.current;
    if (!stableProps.current.isLoading) {
      try {
        stableProps.current.isLoading = true;
        setLoading(true);
        await fetchData(searchText, offset);
        stableProps.current.offset += 1;
      } finally {
        setLoading(false);
        stableProps.current.isLoading = false;
      }
    }
  };

  const toggleMenu = (value: boolean) => {
    if (hasFirstManualOpen.current) {
      setOpen(value);
    }
  };

  useEffect(() => {
    if (hasFirstManualOpen.current) {
      stableProps.current.offset = 0;
      (async () => {
        await loadMore(debouncedSearchText);
        if (debouncedSearchText) {
          toggleMenu(true);
        }
      })();
    }
  }, [debouncedSearchText]);

  const renderInfiniteLoading = () => (
    <Box display="flex" justifyContent="center" key={0}>
      <CircularProgress size={20} />
    </Box>
  );

  const onOptionSelected = (option: SelectOption | null) => {
    onOptionClick(option);
    toggleMenu(false);
  };

  const renderOption = (option: SelectOption, index: number) => {
    const selected = selectedValues?.includes(option.value);
    return (
      <ListItem
        sx={selected ? style.disableOption : {}}
        onClick={() => !selected && onOptionSelected(option)}
        component="li"
        key={index}
      >
        {option.label}
      </ListItem>
    );
  };

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={2} alignItems="flex-end">
        <Input
          {...props}
          containerRef={containerRef}
          variant="outlinedWhite"
          value={inputValue}
          onChange={(e) => {
            toggleMenu(false);
            setInputValue(e.target.value);
          }}
          onFocus={async () => {
            if (!hasFirstManualOpen.current) {
              await loadMore(inputValue);
            }
            hasFirstManualOpen.current = true;
            toggleMenu(true);
          }}
          onBlur={() => setTimeout(() => toggleMenu(false), 200)}
          disabled={!!currentOption}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <>
                {isLoading && (
                  <Box>
                    <CircularProgress size={15} />
                  </Box>
                )}
                {open && !currentOption ? (
                  <ArrowDropUp htmlColor={palette.grey[400]} />
                ) : (
                  <ArrowDropDown htmlColor={palette.grey[400]} />
                )}
              </>
            ),
          }}
        />
        {mode === 'selection' && (
          <Button
            disabled={!currentOption}
            onClick={() => {
              hasFirstManualOpen.current = false;
              stableProps.current.offset = 0;
              onOptionSelected(null);
            }}
            sx={style.clearButton}
            variant="outlined"
          >
            {t('common:clear')}
          </Button>
        )}
      </Stack>

      <Popper
        style={{ width: containerRef.current?.clientWidth }}
        open={open && !currentOption}
        anchorEl={containerRef.current}
      >
        <Paper sx={components?.MuiSelect?.defaultProps?.MenuProps?.PaperProps?.sx}>
          <List component="ul" sx={style.optionList}>
            {data.length === 0 && !isLoading && <Typography sx={style.noOptionsText}>{t('form:noOptions')}</Typography>}
            <InfiniteScroll
              initialLoad={false}
              loadMore={() => loadMore(debouncedSearchText)}
              hasMore={hasMoreItems}
              useWindow={false}
              loader={renderInfiniteLoading()}
              threshold={10}
            >
              {data.map(renderOption)}
            </InfiniteScroll>
          </List>
        </Paper>
      </Popper>
    </Stack>
  );
};

export default SearchSelectInfiniteScroll;
