import { addBusinessDays, format } from 'date-fns';
import {
  Address,
  CreationForm,
  CreationLineDetails,
  LineOption,
  Quotation,
  RequestAccessInput,
  SearchProductsParams,
  Shipping,
  UserPlants,
} from '../types';
import { safeParseFloat } from './helper';

export const getCreationInitialValues = (): CreationForm => {
  return {
    plant: '',
    customerAccountId: '',
    territoryId: '',
    reference1: '',
    reference2: '',
    reference3: '',
    attachments: [],
    poNumber: '',
    requiredArrivalDate: '',
    rfq: '',
    wishLeadTime: null,
    shipping: getShippingInitialValues(),
    lineDetails: [],
    termsAccepted: false,
  };
};

export const getAddressInitialValues = (): Address => {
  return {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    consigneeName: '',
    country: '',
    state: '',
    zipCode: '',
    city: '',
    contactPerson: '',
    contactPhoneNumber: '',
  };
};

export const getShippingInitialValues = (): Shipping => {
  return {
    ...getAddressInitialValues(),
    instructionIds: [],
    saveAddress: false,
    shippingNote: '',
  };
};

export const getSearchProductsParamsInitialValues = (): SearchProductsParams => {
  return {
    plantId: '',
    code: '',
    batteryTypeId: '',
    batteryVoltageId: '',
    dcVoltageId: '',
    familyId: '',
    frequencyId: '',
    horsepowerRatingId: '',
    lineVoltageId: '',
    phaseVoltageId: '',
    power: undefined,
    powerUnitId: '',
    shortCircuitRatingId: '',
    startStopOptionId: '',
    customerAccountId: '',
  };
};

export const getLineDetailsInitialValues = (): CreationLineDetails => {
  return {
    productId: '',
    productCode: '',
    lineNote: '',
    options: [],
    quantity: 1,
    fla: null,
    lrc: null,
    reference: '',
    requiredArrivalDate: '',
  };
};

export const getRequestAccessInitialValues = (): RequestAccessInput => {
  return {
    username: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    company: '',
  };
};

export const isCreationLineDetailsEquals = (prev: CreationLineDetails, next: CreationLineDetails) => {
  if (prev.quantity !== next.quantity) {
    return false;
  }
  if (prev.options.length !== next.options.length) {
    return false;
  }
  return prev.options.every((p) => {
    const nextOption = next.options.find((n) => n.id === p.id);
    return nextOption?.quantity === p.quantity;
  });
};

export const getFormFromQuotation = (
  quotation: Quotation | null,
  currentValues: CreationForm,
  userPlants: UserPlants['plants'],
): CreationForm => {
  if (!quotation || !quotation.details) {
    return getCreationInitialValues();
  }

  const requiredArrivalDate = addBusinessDays(new Date(), quotation.details.leadTime ?? 0);
  const formattedArrivalDate = format(requiredArrivalDate, 'yyyy-MM-dd');

  const { details, lineDetails, shipping } = quotation;
  return {
    ...currentValues,
    quotationId: details.id,
    plant: userPlants.find((p) => p.customerAccounts.some((c) => c.id === details.customerAccountId))?.id ?? '',
    customerAccountId: details.customerAccountId,
    rfq: details.rfq,
    territoryId: details.territoryId,
    reference1: details.reference1,
    reference2: details.reference2,
    reference3: details.reference3,
    requiredArrivalDate: formattedArrivalDate,
    shipping: {
      ...currentValues.shipping,
      ...shipping,
      addressId: shipping.addressId || undefined,
    },
    lineDetails: lineDetails.map((line) => ({
      quotationLineId: line.id,
      productId: line.productId,
      productCode: line.productCode,
      quantity: line.quantity,
      lineNote: line.lineNote,
      fla: safeParseFloat(line.fla?.toString()),
      reference: line.customerReference,
      lrc: safeParseFloat(line.lrc?.toString()),
      options: (line.options as LineOption[]) ?? [],
      requiredArrivalDate: formattedArrivalDate,
    })),
  };
};
