import { MenuItem, MenuItemProps, Select, Stack, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { SelectOption } from '../../../types';
import InputErrorMessage from '../InputErrorMessage';

const style = {
  container: {
    marginBottom: '20px',
  },
  select: {
    backgroundColor: 'white',
    width: '200px',
    height: '50px',
  },
};

type Props = {
  label: string;
  name: string;
  children?: React.ReactElement<MenuItemProps>[];
  control: Control<any, any>;
  hideError?: boolean;
  inputWidth?: any;
  disabled?: boolean;
  data?: SelectOption[];
  onChange?: (value: string) => void;
};

const SelectHookForm = ({
  name,
  control,
  label,
  hideError,
  inputWidth,
  disabled = false,
  data = [],
  onChange: onChangeParent,
}: Props) => (
  <Stack sx={style.container} spacing={1.5}>
    <Typography variant="label">{label}</Typography>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <Stack id={name}>
            <Select
              displayEmpty
              disabled={disabled}
              variant="outlined"
              sx={[style.select, { width: inputWidth }]}
              fullWidth={false}
              value={value}
              onChange={(e) => {
                onChange(e);
                onChangeParent && onChangeParent(e.target.value);
              }}
            >
              {data.map((d) => (
                <MenuItem key={d.value} value={d.value}>
                  {d.label}
                </MenuItem>
              ))}
            </Select>
            <InputErrorMessage errors={errors} name={name} hideError={hideError} />
          </Stack>
        );
      }}
    />
  </Stack>
);

export default SelectHookForm;
