import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { salesOrders as salesOrdersApi } from '../../api';
import { SalesOrder } from '../../types';
import { colors } from '../../style/theme';
import { delay, formatCurrency } from '../../utils/helper';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const style = {
  container: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  title: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  cancelButton: {
    color: colors.primary,
  },
  payNowButton: {
    marginTop: '1em',
    height: '2px',
  },
  payButtonDisabled: {
    backgroundColor: colors.grey4,
  },
  total: {
    paddingTop: 2,
  },
  netTotal: {
    paddingTop: 1,
    paddingBottom: 2,
  },
  shippingAddress: {
    paddingBottom: 2,
  },
};

const PaymentForm = ({ salesOrder }: { salesOrder: SalesOrder }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Retry 5 times or until the paymentConfirmationId is present
  // Workaround to a race condition between stripe payment and ERP data update
  const confirmErpPayment: (retry?: number) => Promise<boolean> = async (retry = 0) => {
    const updatedSalesOrder = (await salesOrdersApi.get(salesOrder.details.id)).data;
    if (retry < 5 && !updatedSalesOrder.details.paymentConfirmationId) {
      retry++;
      await delay(1000);
      return await confirmErpPayment(retry);
    } else {
      return true;
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let fetchedSalesOrder: SalesOrder | null = null;

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    fetchedSalesOrder = (await salesOrdersApi.get(salesOrder.details.id)).data;

    if (salesOrder.details.total === fetchedSalesOrder.details.total) {
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });
      
      if (result.error?.message) {
        setError(result.error?.message);
      } else {
        setError('');
        // Try to confirm the ERP has the paymentConfirmationId
        // before navigating back to the sale order page
        const erpConfirmation = await confirmErpPayment();
        if (erpConfirmation) {
          navigate(routes.salesOrder.path.replace(':id', salesOrder.details.id), { replace: true });
          return;
        }
      }
    } else {
      setError(t('payment:error.general'));
    }
    setIsLoading(false);
  };
  const disabled = !stripe || !elements || salesOrder.details.paymentConfirmationId !== '' || isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography variant="h1" style={style.title}>
          {t('common:payment')}
        </Typography>
        <LoadingButton
          type="submit"
          disabled={disabled}
          loading={isLoading}
          style={disabled ? style.payButtonDisabled : {}}
          variant="containedSmall"
          sx={style.payNowButton}
        >
          {t('common:payNow')}
        </LoadingButton>
      </Stack>
      <Typography variant="h2">{t('sales-orders:details.title', { number: salesOrder.details.soNumber })}</Typography>
      <Divider />
      <Stack direction={{ xs: 'column', sm: 'row' }} display="flex" rowGap={2} columnGap={4}>
        <Stack width="100%">
          <Stack direction="row" justifyContent="space-between" style={style.total}>
            <Typography variant="h6">{t('payment:salesOrder.total')}</Typography>
            <Typography variant="h6">{formatCurrency(salesOrder.details.total - salesOrder.details.taxes)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{t('payment:salesOrder.taxes')}</Typography>
            <Typography variant="h6">{formatCurrency(salesOrder.details.taxes)}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" style={style.netTotal}>
            <Typography variant="h5">{t('payment:salesOrder.netTotal')}</Typography>
            <Typography variant="h5">{formatCurrency(salesOrder.details.total)}</Typography>
          </Stack>
          <Divider />
          <Typography variant="h5" style={style.shippingAddress}>
            {t('payment:shipping.title')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{salesOrder.details.shipToAddress}</Typography>
            </Grid>
          </Grid>
        </Stack>
        <Box width="100%">
          <PaymentElement />
        </Box>
      </Stack>
      {error !== '' && (
        <Box textAlign="center">
          <Typography variant="paragraphBig" color="error">
            {error}
          </Typography>
        </Box>
      )}
    </form>
  );
};

export default PaymentForm;
