import { Box, CircularProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { documents, quotations, salesOrders, shipping as shippingApi, user } from '../../api';
import { CreationContext } from '../../contexts/CreationContext';
import {
  CreationForm,
  CreationResponseState,
  DocumentType,
  Domain,
  Quotation,
  ShippingInstructions,
  UserPlants,
} from '../../types';
import { DATE_REGEX } from '../../utils/constants';
import { getCreationInitialValues, getFormFromQuotation } from '../../utils/form';
import { getDomain } from '../../utils/helper';
import Wizard from '../Wizard/Wizard';
import WizardStep from '../Wizard/WizardStep';
import CreationStep1 from './CreationStep1';
import CreationStep2 from './CreationStep2';
import CreationStep3 from './CreationStep3';
import CreationStepResult from './CreationStepResult';

const FormCreationBase = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { type, setQuotation } = useContext(CreationContext);
  const [initialValues, setInitialValues] = useState(getCreationInitialValues());
  const [userPlants, setUserPlants] = useState<UserPlants['plants']>([]);
  const [instructions, setInstructions] = useState<ShippingInstructions>({ shippingInstructions: [] });
  const currentPlant = useRef('');
  const [isLoading, setLoading] = useState(true);
  const [creationState, setCreationState] = useState<CreationResponseState | null>(null);

  const onPlantChanged = useCallback(
    async (plantId: string) => {
      if (type === 'SalesOrder') {
        if (!plantId) {
          setInstructions({ shippingInstructions: [] });
        } else if (plantId !== currentPlant.current) {
          setInstructions((await shippingApi.getShippingInstructions(plantId)).data);
        }
        currentPlant.current = plantId;
      }
    },
    [type],
  );

  useEffect(() => {
    const initialize = async () => {
      try {
        const isFiretrol = getDomain() === Domain.firetrol;
        const results = (await user.getPlants()).data.plants;
        setUserPlants(results);
        let plant = results.length === 1 ? results[0] : null;
        if (isFiretrol && results.length > 0) {
          plant = results.find((r) => r.name.toLowerCase() === Domain.firetrol) ?? plant;
        }

        let quotation: Quotation | null = null;
        const paramQuotationId = searchParams.get('quotation');
        if (paramQuotationId && type === 'SalesOrder') {
          quotation = (await quotations.get(paramQuotationId)).data;
        }
        if (quotation?.details && setQuotation) {
          setQuotation(quotation);
          setInitialValues((prev) => getFormFromQuotation(quotation, prev, results));
        } else {
          setInitialValues((prev) => {
            let customerAccountId = prev.customerAccountId;
            if (plant?.customerAccounts.length === 1) {
              customerAccountId = plant?.customerAccounts[0].id;
            }
            return { ...prev, plant: plant?.id ?? '', customerAccountId };
          });
        }
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [onPlantChanged, searchParams, setQuotation, type]);

  const onSubmit = async (values: CreationForm) => {
    try {
      setCreationState({ state: 'loading', code: '' });
      let responseCode = '';
      let message = '';
      if (type === 'Quotation') {
        responseCode = (await quotations.create(values)).data.quotationNumber;
      } else {
        const response = (await salesOrders.create(values)).data;
        if (response.soNumber) {
          if (values.attachments?.length) {
            const resp = await documents.upload(values.attachments, response.soId, DocumentType.SalesOrder);
            if (resp.data !== "OK") {
                message = t('form:final.attachmentUploadError')
            }
          }
          responseCode = response.soNumber;
        }
      }
      if (!responseCode) {
        throw 'no code';
      }
      setCreationState({ state: 'success', code: responseCode, message });
    } catch (ex: any) {
      const message = ex.response?.data?.message ? ex.response?.data.message : t('common:unknownError');
      setCreationState({ state: 'error', code: '', message });
      throw ex;
    }
  };

  const validateRequiredArrivalDate = (value: string | undefined) => {
    if (!value) {
      return true;
    }

    const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
    const parsed = new Date(new Date(value).setUTCHours(0, 0, 0, 0));
    return today < parsed;
  };

  const validationStep1 = Yup.object({
    plant: Yup.string().required(t('form:error.fieldRequired')),
    customerAccountId: Yup.string().required(t('form:error.fieldRequired')),
    territoryId: Yup.string().required(t('form:error.fieldRequired')),
    reference1: Yup.string().max(255, t('form:error.maxLength', { length: 255 })),
    reference2: Yup.string().max(255, t('form:error.maxLength', { length: 255 })),
    reference3: Yup.string().max(255, t('form:error.maxLength', { length: 255 })),
    requiredArrivalDate: Yup.string()
      .matches(DATE_REGEX, { message: t('form:error.invalidDateForm'), excludeEmptyString: true })
      .test('notBeforeToday', t('form:error.invalidDateRange'), validateRequiredArrivalDate),
    poNumber: type === 'SalesOrder' ? Yup.string().required(t('form:error.fieldRequired')) : Yup.string().notRequired(),
    attachments: Yup.array().notRequired(),
  });

  const validationStep2 = Yup.object().shape({
    shipping: Yup.object().shape({
      addressId: Yup.string().when(['addressLine1'], {
        is: (val: string) => !val,
        then: (schema) => schema.required(t('form:error.fieldRequired')),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  });

  const validationStep3 = Yup.object().shape({
    termsAccepted: type === 'SalesOrder' ? Yup.boolean().required(t('form:error.fieldRequired')).oneOf([true], t('form:error.fieldRequired')) : Yup.boolean().notRequired(),
    lineDetails: Yup.array(
      Yup.object().shape({
        quantity: Yup.number().min(1).required().positive().integer(),
        requiredArrivalDate: Yup.string().notRequired().matches(DATE_REGEX, { excludeEmptyString: true }),
        options: Yup.array(
          Yup.object().shape({
            quantity: Yup.number().min(1).required().positive().integer(),
          }),
        ),
      }),
    ).min(1, t('form:error.productsRequired')),
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Wizard
        initialValues={initialValues}
        onSubmit={onSubmit}
        title={type === 'Quotation' ? t('quotations:creation.title') : t('sales-orders:creation.title')}
        state={creationState?.state}
      >
        <WizardStep validationSchema={validationStep1} title={t('form:step1.title')}>
          <CreationStep1 onPlantChanged={onPlantChanged} userPlants={userPlants} />
        </WizardStep>
        <WizardStep validationSchema={validationStep2} title={t('form:step2.title')}>
          <CreationStep2 instructions={instructions.shippingInstructions} userPlants={userPlants} />
        </WizardStep>
        <WizardStep validationSchema={validationStep3} title={t('form:step3.title')}>
          <CreationStep3 />
        </WizardStep>
      </Wizard>
      {creationState && <CreationStepResult onPreviousClick={() => setCreationState(null)} {...creationState} />}
    </>
  );
};
export default FormCreationBase;
