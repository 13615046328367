import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Navbar } from './components/Navbar/Navbar';
import useAnalytics from './hooks/useAnalytics';
import useAuth from './hooks/useAuth';
import routes from './routes';
import theme from './style/theme';
import { getDomain } from './utils/helper';

function App() {
  useAnalytics();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { isLoaded } = useAuth();

  useEffect(() => {
    const setDocumentTitle = () => (document.title = i18n.t('common:documentTitle', { domain: getDomain() }));
    setDocumentTitle();
    i18n.on('languageChanged', setDocumentTitle);
    return () => i18n.off('languageChanged', setDocumentTitle);
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {isLoaded && (
          <>
            {pathname !== routes.notFound.path && <Navbar />}
            <Routes>
              {Object.values(routes).map((v) => (
                <Route key={v.path} {...v}></Route>
              ))}
              <Route
                path="*"
                element={<Navigate replace to={routes.notFound.path} state={{ path: pathname }} />}
              ></Route>
            </Routes>
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
