import { Print, RequestQuote } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { documents as documentsApi } from '../../api';
import routes from '../../routes';
import theme, { sxHover } from '../../style/theme';
import { DocumentType, Quotation, QuotationStatus } from '../../types';
import AlertIcon from '../Dashboard/AlertIcon';
import DashboardItemDetails from '../Dashboard/DashboardItemDetails';
import StatusChip from '../StatusChip';

const style = {
  printButton: {
    marginRight: 2,
    color: theme.palette.grey[400],
    ...sxHover,
  },
  header: {
    padding: '0px 20px 30px 20px',
  },
};

type Props = {
  quotation: Quotation;
};

const QuotationHeader = ({ quotation }: Props) => {
  const { details } = quotation;
  const { t } = useTranslation();
  const [isDownloading, setDownloading] = useState(false);

  const getHeaderData = () => [
    {
      title: t('quotations:details.rfq'),
      value: details.rfq,
      gridSize: 3,
    },
    {
      title: t('quotations:details.references'),
      value: details.references,
      gridSize: 4.5,
    },
    {
      title: t('common:status'),
      value: renderStatus(),
      gridSize: 1.2,
    },
    {
      title: t('quotations:details.expiredDate'),
      value: details.expiredDate,
      gridSize: 1,
    },
    {
      title: t('quotations:details.leadTime'),
      value: details.leadTime,
      gridSize: 1.3,
      isCentered: true,
      tooltip: t('quotations:details.leadTimeInfo'),
    },
    {
      title: t('quotations:details.currency'),
      value: details.currency,
      gridSize: 1,
    },
  ];

  const onPrintPress = async () => {
    setDownloading(true);
    try {
      await documentsApi.download(details.id, DocumentType.Quotation);
    } finally {
      setDownloading(false);
    }
  };

  const renderStatus = () => {
    const { status, alert } = details;
    return (
      <Stack direction="row" spacing={1} display="flex" alignItems="center">
        <StatusChip
          text={t(`quotations:status.${status}`)}
          size="large"
          color={status === QuotationStatus.Active ? 'active' : 'disabled'}
        />
        <AlertIcon size="small" alertText={alert} />
      </Stack>
    );
  };

  return (
    <Stack spacing={5} sx={style.header}>
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography variant="h1">
          {t('quotations:details.title', {
            number: details.quotationNumber,
            revision: details.revision,
          })}
        </Typography>
        <Stack direction="row" spacing={3}>
          {details.status === QuotationStatus.Active && (
            <LoadingButton
              href={`${routes.createSalesOrder.path}?quotation=${details.id}`}
              sx={style.printButton}
              startIcon={<RequestQuote />}
            >
              <Typography variant="buttonMedium">{t('quotations:details.convert')}</Typography>
            </LoadingButton>
          )}
          {details.status !== QuotationStatus.Draft && (
            <LoadingButton loading={isDownloading} sx={style.printButton} startIcon={<Print />} onClick={onPrintPress}>
              <Typography variant="buttonMedium">{t('common:print')}</Typography>
            </LoadingButton>
          )}
        </Stack>
      </Stack>
      <Box>
        <DashboardItemDetails data={getHeaderData()}></DashboardItemDetails>
      </Box>
    </Stack>
  );
};

export default QuotationHeader;
