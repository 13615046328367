declare global {
  interface Window {
    env: any;
  }
}

type ProcessEnv = {
  REACT_APP_API_URL: string;
  REACT_APP_ENVIRONMENT: string;
  REACT_APP_CLIENT_PORTAL_API_JWT_TORNATECH: string;
  REACT_APP_CLIENT_PORTAL_API_JWT_FIRETROL: string;
  REACT_APP_RECAPTCHA_FIRETROL_KEY: string;
  REACT_APP_RECAPTCHA_TORNATECH_KEY: string;
  REACT_APP_GA_ID_FIRETROL: string;
  REACT_APP_GA_ID_TORNATECH: string;
  REACT_APP_ATTACHMENTS_EXTENSIONS: string;
  REACT_APP_TORNATECH_RBC_USD_STRIPE_ACCOUNT_ID: string;
  REACT_APP_TORNATECH_RBC_CAD_STRIPE_ACCOUNT_ID: string;
  REACT_APP_TORNATECH_CND_USD_STRIPE_ACCOUNT_ID: string;
  REACT_APP_FIRETROL_STRIPE_ACCOUNT_ID: string;
  REACT_APP_TORNATECH_CND_USD_STRIPE_API_PUBLIC_KEY: string;
  REACT_APP_TORNATECH_RBC_USD_STRIPE_API_PUBLIC_KEY: string;
  REACT_APP_TORNATECH_RBC_CAD_STRIPE_API_PUBLIC_KEY: string;
  REACT_APP_FIRETROL_CND_USD_STRIPE_API_PUBLIC_KEY: string;
};

export const env: ProcessEnv = { ...process.env, ...window.env };
