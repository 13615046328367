import { Delete } from '@mui/icons-material';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { MutableRefObject, useContext, useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { options as optionsApi } from '../../../api';
import { CreationContext } from '../../../contexts/CreationContext';
import { CreationForm, LineOption, SelectOption } from '../../../types';
import { LIMIT_OPTIONS } from '../../../utils/constants';
import { getCurrentLanguage } from '../../../utils/helper';
import NumberHookForm from '../../FormControls/HookForm/NumberHookForm';
import SearchSelectInfiniteScroll from '../../FormControls/SearchSelectInfiniteScroll';

const style = {
  parentRow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  parentCell: {
    verticalAlign: 'middle',
  },
  optionTable: {
    '& .MuiTableBody-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiTableHead-root': {
      border: 'none',
    },
  },
  noOptionText: {
    marginTop: 2,
  },
  deleteLineCell: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  readOnlyOptionContainer: {
    marginTop: 1,
  },
  optionDescriptionTrigger: {
    cursor: 'pointer',
    marginLeft: '5px',
    marginBottom: '-4px',
  },
  tableRowChildContainer: {
    padding: 0,
  },
  tableRowChild: {
    padding: '10px 15px',
  }
};

type Props = {
  productId: string;
  namePrefix: `lineDetails.${number}.options`;
  selectRef: MutableRefObject<HTMLInputElement | undefined>;
};

type QueryOptions = {
  options: LineOption[];
  hasMoreItems: boolean;
};

const ProductOptionsTable = ({ productId, namePrefix, selectRef }: Props) => {
  const { t } = useTranslation();
  const { setValue, control, getValues } = useFormContext<CreationForm>();
  const context = useContext(CreationContext);
  const { fields } = useFieldArray<CreationForm, typeof namePrefix, 'tempId'>({
    name: namePrefix,
    keyName: 'tempId',
  });
  const [query, setQuery] = useState<QueryOptions>({ options: [], hasMoreItems: true });
  const [optionError, setOptionError] = useState('');

  const fetchOptions = async (keyword: string, offset: number) => {
    setQuery((prev) => ({ ...prev, options: offset == 0 ? [] : prev.options }));
    const customerAccountId = getValues('customerAccountId');
    const results = await optionsApi.searchOptions(LIMIT_OPTIONS, offset, keyword, customerAccountId, productId);
    setQuery((prev) => {
      const options = results.data.data;
      return {
        hasMoreItems: options.length === LIMIT_OPTIONS,
        options: offset == 0 ? options : [...prev.options, ...options],
      };
    });
  };

  const appendOption = (selectedOption: SelectOption | null) => {
    const option = selectedOption && query.options.find((r) => r.id === selectedOption.value);
    if (option && !fields.some((o) => o.id == selectedOption.value)) {
      if (!option.allowMultiple && fields.some((o) => o.category === option.category)) {
        setOptionError(t('form:error.allowMultiple', { category: option.category }));
        return;
      }
      setValue(namePrefix, [...getValues(namePrefix), { ...option, quantity: 1 }]);
      setOptionError('');
    }
  };

  const removeOption = (id: string) => {
    setValue(
      namePrefix,
      getValues(namePrefix).filter((o) => o.id != id),
    );
    setOptionError('');
  };

  const getSelectOptions = (): SelectOption[] =>
    query.options.map((o) => ({ label: `${o.code} - ${o.description[getCurrentLanguage()]}`, value: o.id }));

  const renderOptionTableRow = (option: FieldArrayWithId<CreationForm, typeof namePrefix, 'tempId'>, index: number) => (
    <React.Fragment key={option.tempId}>
      <TableRow sx={style.parentRow}>
        <TableCell sx={style.parentCell}>
          {option.code}
        </TableCell>
        <TableCell>
          {option.description[getCurrentLanguage()]}
        </TableCell>
        <TableCell align="center" width="1px">
          <NumberHookForm
            disabled={!!context.quotation}
            min={1}
            max={option.maxQuantity}
            setValue={setValue}
            name={`${namePrefix}.${index}.quantity`}
            control={control}
          />
        </TableCell>
        {!context.quotation && (
          <TableCell width="1px" sx={style.deleteLineCell}>
            <IconButton onClick={() => removeOption(option.id)}>
              <Delete />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );

  const renderEmptyOptions = () => {
    let message = t('form:step3.list.noOptions');
    if (context.quotation) {
      message = t('form:noOptions');
    }

    return <Typography sx={style.noOptionText}>{message}</Typography>;
  };

  return (
    <>
      {!context.quotation && (
        <SearchSelectInfiniteScroll
          inputRef={selectRef}
          label={t('form:step3.list.selectOption')}
          labelVariant="paragraphSmall"
          data={getSelectOptions()}
          onOptionClick={appendOption}
          fetchData={fetchOptions}
          hasMoreItems={query.hasMoreItems}
          selectedValues={fields.map((f) => f.id)}
          mode="clickOnly"
          placeholder={t('form:step3.list.selectOptionPlaceholder')}
        />
      )}
      {optionError && <Typography variant="inputError">{optionError}</Typography>}
      {fields.length === 0 ? (
        renderEmptyOptions()
      ) : (
        <TableContainer sx={[style.optionTable, !!context.quotation && style.readOnlyOptionContainer]}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="paragraphSmall">{t('form:step3.list.code')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="paragraphSmall">{t('form:step3.list.description')}</Typography>
                </TableCell>
                <TableCell align="center" width="1px">
                  <Typography variant="paragraphSmall">{t('common:product.quantity')}</Typography>
                </TableCell>
                <TableCell width="1px" sx={style.deleteLineCell} />
              </TableRow>
            </TableHead>
            <TableBody>{fields.map(renderOptionTableRow)}</TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default ProductOptionsTable;
