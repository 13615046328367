import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../style/theme';
import { MAX_FILE_SIZE_MB } from '../../../utils/constants';

const style = {
  attachmentsActionContainer: {
    border: '1px dashed' + theme.palette.grey[300],
    height: '130px',
    borderRadius: '10px',
    marginTop: '12px',
  },
  attachmentsContainer: {
    paddingTop: '15px',
    marginBottom: '10px',
  },
  addAttachments: {
    marginLeft: '10px',
  },
  dragOver: {
    backgroundColor: theme.palette.common.white,
    border: '1px dashed' + theme.palette.primary.main,
  },
};

type Props = {
  onDrop: (files: FileList) => void;
  onClick: () => void;
  extensions: string;
};

const AttachmentsBanner = ({ onDrop, onClick, extensions }: Props) => {
  const { t } = useTranslation();
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(true);
    ev.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragOver(false);
    onDrop(ev.dataTransfer.files);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const getHelpText = () => {
    let help = '';
    if (extensions) {
      help += t('form:step1.supportedExtensions', { extensions }) + '. ';
    }

    return help + t('form:step1.supportedFileSize', { size: MAX_FILE_SIZE_MB });
  };

  return (
    <Box sx={style.attachmentsContainer}>
      <Typography variant="label">{t('form:step1.attachDocuments')}</Typography>
      <Box
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={[style.attachmentsActionContainer, isDragOver && style.dragOver]}
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <Typography variant="paragraphBig">{t('form:step1.attachDocumentsHelp')}</Typography>
          <Button onClick={onClick} sx={style.addAttachments} variant="containedSmall">
            {t('form:step1.attachDocumentsAction')}
          </Button>
        </Box>

        <Typography variant="caption">{getHelpText()}</Typography>
      </Box>
    </Box>
  );
};
export default AttachmentsBanner;
