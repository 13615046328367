import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreationContext } from '../../contexts/CreationContext';
import routes from '../../routes';
import { CreationResponseState } from '../../types';

const style = {
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#f6f6f6',
    paddingTop: '150px',
  },
  icon: {
    fontSize: '64px',
  },
};

type Props = {
  onPreviousClick: () => void;
} & CreationResponseState;

const CreationStepResult = ({ state, code, onPreviousClick, message }: Props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const context = useContext(CreationContext);
  const indexValueAt = context.type === 'Quotation' ? 0 : 1;

  const renderSuccess = () => (
    <Stack spacing={4} flexDirection="column" alignItems="flex-start">
      <CheckCircle sx={style.icon} htmlColor={palette.success.main} />
      <Typography variant="h1">{t('form:final.success', { indexValueAt, code })}</Typography>
      <Typography variant="paragraphBig">
        {context.type === 'SalesOrder' ? `${t('form:final.helperOrder')} ${message}` : t('form:final.helperQuote')}
      </Typography>
      <Button
        href={context.type === 'SalesOrder' ? routes.salesOrders.path : routes.quotations.path}
        variant="contained"
      >
        {t('form:final.goTo', {
          routeName: context.type === 'SalesOrder' ? t('sales-orders:list.title') : t('quotations:list.title'),
        })}
      </Button>
    </Stack>
  );

  const renderError = () => (
    <Stack spacing={4} flexDirection="column" alignItems="flex-start">
      <Cancel sx={style.icon} htmlColor={palette.error.main} />
      <Typography variant="paragraphBig">{t('form:final.error', { indexValueAt })}</Typography>
      <Typography variant="caption">{t('form:error.details', { message })}</Typography>
      <Button onClick={onPreviousClick} variant="outlined">
        {t('form:previous')}
      </Button>
    </Stack>
  );

  const renderLoading = () => (
    <Stack spacing={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <CircularProgress size={50} />
      <Typography variant="paragraphBig">{t('form:final.loading', { indexValueAt })}</Typography>
    </Stack>
  );

  return (
    <Box sx={style.container}>
      <Container>
        {state === 'loading' && renderLoading()}
        {state === 'success' && renderSuccess()}
        {state === 'error' && renderError()}
      </Container>
    </Box>
  );
};
export default CreationStepResult;
