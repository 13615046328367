import { Box, Divider } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { quotations as quotationsApi } from '../../api';
import { CreationContext } from '../../contexts/CreationContext';
import { CreationForm, Quotation, QuotationStatus, SearchQuotation, SelectOption, UserPlants } from '../../types';
import { LIMIT_QUOTATIONS } from '../../utils/constants';
import { getFormFromQuotation } from '../../utils/form';
import SearchSelectInfiniteScroll from '../FormControls/SearchSelectInfiniteScroll';

const style = {
  divider: {
    marginTop: '40px',
  },
  quoteContainer: {
    marginTop: '-20px',
    marginBottom: '40px',
  },
};

type QueryQuotations = {
  quotations: SearchQuotation[];
  hasMoreItems: boolean;
};

type Props = {
  userPlants: UserPlants['plants'];
};

const SelectQuotation = ({ userPlants }: Props) => {
  const { t } = useTranslation();
  const { quotation, setQuotation } = useContext(CreationContext);
  const { reset, getValues } = useFormContext<CreationForm>();
  const [query, setQuery] = useState<QueryQuotations>({ quotations: [], hasMoreItems: true });

  useEffect(() => {
    if (getValues('quotationId') !== quotation?.details.id) {
      reset(getFormFromQuotation(quotation, getValues(), userPlants));
    }
  }, [quotation, reset, userPlants, getValues]);

  const fetchQuotations = async (keyword: string, offset: number) => {
    setQuery((prev) => ({ ...prev, quotations: offset == 0 ? [] : prev.quotations }));
    const results = await quotationsApi.search(LIMIT_QUOTATIONS, offset, keyword, QuotationStatus.Active);
    setQuery((prev) => {
      const quotations = results.data.data;
      return {
        hasMoreItems: quotations.length === LIMIT_QUOTATIONS,
        quotations: offset == 0 ? quotations : [...prev.quotations, ...quotations],
      };
    });
  };

  const parseQuotationOption = (quotation: SearchQuotation) => ({
    label: `${quotation.quotationNumber + (quotation.revision ? `-${quotation.revision}` : '')}`,
    value: quotation.id,
  });

  const onQuotationSelected = async (option: SelectOption | null) => {
    let newQuotation: Quotation | null = null;
    if (option?.value) {
      newQuotation = (await quotationsApi.get(option.value)).data;
    }
    setQuotation && setQuotation(newQuotation);
  };

  return (
    <Box sx={style.quoteContainer}>
      <Box width={500}>
        <SearchSelectInfiniteScroll
          label={t('form:step1.selectQuote')}
          data={query.quotations.map(parseQuotationOption)}
          onOptionClick={onQuotationSelected}
          fetchData={fetchQuotations}
          hasMoreItems={query.hasMoreItems}
          mode="selection"
          currentOption={quotation ? parseQuotationOption(quotation.details) : null}
        />
      </Box>
      <Divider sx={style.divider} />
    </Box>
  );
};
export default SelectQuotation;
