import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineOption } from '../../types';
import BaseDrawer from '../BaseDrawer';

const style = {
  content: {
    marginTop: '20px',
  },
};

type Props = {
  productCode: string;
  description: string;
  options: string[] | LineOption[];
  isOpen: boolean;
  onClose: () => void;
};

const ProductDrawer = ({ isOpen, onClose, productCode, description, options }: Props) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const renderTitle = () => {
    return (
      <Stack spacing={1}>
        <Typography color={palette.grey[400]} variant="menuItem">
          {t('common:product.code')}
        </Typography>
        <Typography variant="h2">{productCode}</Typography>
      </Stack>
    );
  };

  const renderOptionText = (option: string | LineOption) => {
    if (typeof option === 'string') {
      return option;
    }

    return option.code;
  };

  return (
    <BaseDrawer title={renderTitle()} isOpen={isOpen} onClose={onClose}>
      <Stack sx={style.content} spacing={3}>
        <Stack spacing={1}>
          <Typography color={palette.grey[400]} variant="menuItem">
            {t('common:product.description')}
          </Typography>
          <Typography whiteSpace="pre-line" fontWeight={400} variant="paragraphSmall">
            {description}
          </Typography>
        </Stack>
        <Stack spacing={1}>
          <Typography color={palette.grey[400]} variant="menuItem">
            {t('common:product.options')}
          </Typography>
          {options.map((option, i) => (
            <Typography key={i} fontWeight={400} variant="menuItem">
              {`- ${renderOptionText(option)}`}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </BaseDrawer>
  );
};

export default ProductDrawer;
