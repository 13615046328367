import { Box, Typography } from '@mui/material';
import { SalesOrder } from '../../types';
import { getCurrentLanguage } from '../../utils/helper';
import { useTranslation } from 'react-i18next';

type Props = {
  currentSalesOrder?: SalesOrder;
};

const PaymentMessage = ({ currentSalesOrder }: Props) => {
  const { t } = useTranslation();

  if (
    currentSalesOrder?.details.ccDisabledMessage &&
    currentSalesOrder?.details.ccDisabledMessage.en !== '' &&
    currentSalesOrder?.details.ccDisabledMessage.fr !== ''
  ) {
    return (
      <Box textAlign="center">
        <Typography variant="paragraphBig" color="error">
          {currentSalesOrder?.details.ccDisabledMessage[getCurrentLanguage()]}
        </Typography>
      </Box>
    );
  }
  if (!currentSalesOrder?.details.ccAuthorized || currentSalesOrder?.details.paymentConfirmationId !== "") {
    return (
      <Box textAlign="center">
        <Typography variant="paragraphBig" color="error">
          {t('sales-orders:payment.cannotPay')}
        </Typography>
      </Box>
    );
  }
  return <></>;
};

export default PaymentMessage;
