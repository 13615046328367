import React from 'react';
import { Quotation } from '../types';

interface CreationContextType {
  type: 'Quotation' | 'SalesOrder';
  quotation: Quotation | null;
  setQuotation?: (quotation: Quotation | null) => void;
}

export const CreationContext = React.createContext<CreationContextType>({} as CreationContextType);
