import { Typography } from '@mui/material';
import React, { useImperativeHandle, useRef } from 'react';
import BaseModal from './BaseModal';

type Props = {
  isVisible: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
};

type PromiseInfo = {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
};

const ConfirmationModal = React.forwardRef((props: Props, ref) => {
  const promiseInfo = useRef<PromiseInfo | null>(null);

  useImperativeHandle(ref, () => ({
    waitForResponse: () => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };
      });
    },
  }));

  const onConfirm = () => {
    promiseInfo.current?.resolve(true);
    props.onConfirm();
  };

  const onCancel = () => {
    promiseInfo.current?.resolve(false);
    props.onCancel();
  };

  return (
    <BaseModal {...props} onConfirm={onConfirm} onCancel={onCancel}>
      <Typography variant="paragraphBig">{props.description}</Typography>
    </BaseModal>
  );
});

export default ConfirmationModal;
