import React, { useRef, useState } from 'react';
import { useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import usePrompt from '../../hooks/usePrompt';
import ConfirmationModal from '../ConfirmationModal';

type Props = {
  isSuccess: boolean;
};

const WizardCancellationModal = ({ isSuccess }: Props) => {
  const { t } = useTranslation();
  const formState = useFormState();
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const modalRef = useRef<any>(null);

  usePrompt(async () => {
    setConfirmationVisible(true);
    return await modalRef.current.waitForResponse();
  }, formState.isDirty && !isSuccess);

  return (
    <ConfirmationModal
      ref={modalRef}
      isVisible={isConfirmationVisible}
      onCancel={() => setConfirmationVisible(false)}
      onConfirm={() => setConfirmationVisible(false)}
      title={t('form:abortTitle')}
      description={t('form:abortDescription')}
      confirmText={t('auth:settings.confirm')}
    />
  );
};

export default WizardCancellationModal;
