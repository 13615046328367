import { Delete } from '@mui/icons-material';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { env } from '../../../env';
import { MAX_FILE_SIZE_MB } from '../../../utils/constants';
import { bytesToMB, formatBytes, getFileExtension } from '../../../utils/helper';
import AttachmentsBanner from './AttachmentsBanner';

type Props = {
  onChange: (files: File[]) => void;
  files: File[];
};

const supportedExtensions = env.REACT_APP_ATTACHMENTS_EXTENSIONS?.trim().toLowerCase() ?? '';

const AttachmentsDropZone = ({ onChange, files }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const fileListToArray = (list: FileList | null) => {
    const result: File[] = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        const file = list.item(i);
        if (file) {
          result.push(file);
        }
      }
    }

    return result;
  };

  const removeFile = (index: number) => {
    onChange(files.filter((_, i) => i !== index));
  };

  const handleFileSelection = (list: FileList | null) => {
    const updatedList = [...files];
    fileListToArray(list).forEach((file) => {
      const isInSize = bytesToMB(file.size) < MAX_FILE_SIZE_MB;
      const extension = getFileExtension(file.name).toLowerCase();
      const isInExtension = !supportedExtensions || supportedExtensions.split(',').includes(extension);
      if (!updatedList.some(({ name }) => name === file.name) && isInSize && isInExtension) {
        updatedList.push(file);
      }
    });
    onChange(updatedList);
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    handleFileSelection(ev.target.files);
    ev.target.value = '';
  };

  const handleDrop = (files: FileList) => {
    handleFileSelection(files);
  };

  const renderFiles = (file: File, index: number) => {
    return (
      <Stack key={file.name}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Stack>
            <Typography variant="paragraphSmall">{file.name}</Typography>
            <Typography variant="caption">{formatBytes(file.size)}</Typography>
          </Stack>
          <IconButton onClick={() => removeFile(index)}>
            <Delete />
          </IconButton>
        </Box>
        <Divider />
      </Stack>
    );
  };

  return (
    <Stack spacing={1}>
      <AttachmentsBanner extensions={supportedExtensions} onClick={handleClick} onDrop={handleDrop} />
      <input type="file" hidden ref={inputRef} multiple onChange={handleChange} accept={supportedExtensions || '*'} />
      <Stack spacing={1}>{files?.map(renderFiles)}</Stack>
    </Stack>
  );
};
export default AttachmentsDropZone;
