import { Add, Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, TableCell, TableRow } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { products as productsApi } from '../../../api';
import { CreationContext } from '../../../contexts/CreationContext';
import useDebounce from '../../../hooks/useDebounce';
import { CreationForm, CreationLineDetails, ProductPrice } from '../../../types';
import { isCreationLineDetailsEquals } from '../../../utils/form';
import { formatCurrency, getCurrentLanguage } from '../../../utils/helper';
import InputHookForm from '../../FormControls/HookForm/InputHookForm';
import NumberHookForm from '../../FormControls/HookForm/NumberHookForm';
import ProductOptionsTable from './ProductOptionsTable';
import React from 'react';

const style = {
  parentRow: {
    '& td': {
      borderBottom: 'none',
      paddingBottom: '8px',
    }
  },
  parentCell: {
    verticalAlign: 'middle',
  },
  parentRowDescription: {
    '& td': {
      fontSize: '12px',
      paddingTop: '8px',
    }
  },
  productDescriptionEllipsed: {
    '& td': {
      maxWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },
  tableRowChildContainer: {
    backgroundColor: '#F9FAFB',
    padding: 0,
  },
  tableRowChild: {
    padding: '15px',
  },
  tableRowField: {
    marginTop: -3,
  },
};

type QueryPrice = {
  isLoading: boolean;
  details: ProductPrice['productPriceDetails'] | null;
};

type Props = {
  isOpen: boolean;
  toggleProductRow: () => void;
  namePrefix: `lineDetails.${number}`;
  line: CreationLineDetails;
  onDelete: () => void;
};

const ProductRow = ({
  isOpen = false,
  toggleProductRow,
  line,
  namePrefix,
  onDelete,
}: Props) => {
  const selectRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const { quotation, type } = useContext(CreationContext);
  const { setValue, control, getValues } = useFormContext<CreationForm>();
  const watchChanges = useWatch({ name: namePrefix });
  const debouncedChanges = useDebounce(watchChanges, 1000, isCreationLineDetailsEquals);
  const [queryPrice, setQueryPrice] = useState<QueryPrice>({ isLoading: false, details: null });

  useEffect(() => {
    (async () => {
      const customerAccountId = getValues('customerAccountId');
      const territoryId = getValues('territoryId');
      const quotationId = getValues('quotationId');
      if (customerAccountId && territoryId && !quotationId) {
        try {
          setQueryPrice({ isLoading: true, details: null });
          const price = await productsApi.getPrice(customerAccountId, territoryId, debouncedChanges);
          setQueryPrice({ isLoading: false, details: price.data.productPriceDetails });
        } catch {
          setQueryPrice((prev) => ({ ...prev, isLoading: false }));
        }
      }
    })();
  }, [debouncedChanges, namePrefix, getValues]);

  const focusApplicableOptionsSelect = () => {
    if (!isOpen) {
      toggleProductRow();
    }
    setTimeout(() => {
      selectRef.current?.focus();
    }, 0);
  }

  const getQuotationLine = (quotationLineId: string | undefined) =>
    quotation?.lineDetails.find((line) => line.id === quotationLineId);

  const renderTableRowParent = () => {
    const quotationLine = getQuotationLine(line.quotationLineId);
    const descriptionStyle = [style.parentRowDescription, !isOpen && style.productDescriptionEllipsed];
    const descriptionColSpan = !quotation ? 8 : 6;
    return (
      <React.Fragment key={line.productId + 'parent'}>
        <TableRow sx={style.parentRow}>
          <TableCell sx={style.parentCell}>{line.productCode}</TableCell>
          <TableCell sx={style.parentCell} align="center">
            {watchChanges.options.length}
            <IconButton onClick={() => focusApplicableOptionsSelect()}>
              <Add />
            </IconButton>
          </TableCell>
          <TableCell width="1px" align="center">
            <NumberHookForm
              disabled={!!quotation}
              min={1}
              setValue={setValue}
              name={`${namePrefix}.quantity`}
              control={control}
            />
          </TableCell>
          {!quotation && (
            <TableCell sx={style.parentCell} align="right">
              {renderPriceCell(queryPrice.details?.netBase)}
            </TableCell>
          )}
          {!quotation && (
            <TableCell sx={style.parentCell} align="right">
              {renderPriceCell(queryPrice.details?.netOption)}
            </TableCell>
          )}
          <TableCell sx={style.parentCell} align="right">
            {renderPriceCell(quotationLine?.unitPrice ?? queryPrice.details?.unitPrice)}
          </TableCell>
          <TableCell sx={style.parentCell} align="right">
            {renderPriceCell(quotationLine?.totalPrice ?? queryPrice.details?.totalPrice)}
          </TableCell>
          <TableCell sx={style.parentCell} align="right">
            {queryPrice.isLoading ? (
              <Box marginRight={1.8}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <IconButton size="small" onClick={() => toggleProductRow()}>
                {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={descriptionStyle}>
          <TableCell colSpan={descriptionColSpan}>{quotationLine?.description || line.productDescription?.[getCurrentLanguage()]}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const isSaleOrder = type === 'SalesOrder';

  const renderTableRowChild = () => (
    <TableRow key={line.productId + 'child'}>
      <TableCell sx={style.tableRowChildContainer} colSpan={8}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={1} alignItems="flex-end" sx={style.tableRowChild}>
            <Grid item sm={12} md={isSaleOrder ? 3 : 4}>
              <InputHookForm
                label={t('form:step3.list.reference')}
                control={control}
                labelVariant="paragraphSmall"
                name={`${namePrefix}.reference`}
              />
            </Grid>

            {type === 'SalesOrder' && (
              <Grid item sm={12} md={2}>
                <InputHookForm
                  label={t('form:step3.list.requiredArrivalDate')}
                  control={control}
                  labelVariant="paragraphSmall"
                  placeholder="YYYY-MM-DD"
                  disabled={!!quotation}
                  hideError
                  name={`${namePrefix}.requiredArrivalDate`}
                />
              </Grid>
            )}

            <Grid item sm={12} md={1}>
              <InputHookForm
                type="number"
                disabled={!!quotation}
                label={t('form:step3.list.fla')}
                control={control}
                labelVariant="paragraphSmall"
                name={`${namePrefix}.fla`}
              />
            </Grid>

            <Grid item sm={12} md={1}>
              <InputHookForm
                type="number"
                disabled={!!quotation}
                label={t('form:step3.list.lrc')}
                control={control}
                labelVariant="paragraphSmall"
                name={`${namePrefix}.lrc`}
              />
            </Grid>

            <Grid item sm={12} md={isSaleOrder ? 5 : 6}>
              <InputHookForm
                label={t('form:step3.list.lineNote')}
                control={control}
                rows={3}
                labelVariant="paragraphSmall"
                name={`${namePrefix}.lineNote`}
              />
            </Grid>
          </Grid>

          {!quotation && (
            <Grid item sm={12} sx={style.tableRowChild}>
              <Button onClick={onDelete} startIcon={<Delete />} variant="outlined">
                {t('form:step3.list.deleteLine')}
              </Button>
            </Grid>
          )}

          <Grid container sx={style.tableRowChild}>
            <Grid item sm={12}>
              <Box sx={style.tableRowField}>
                <ProductOptionsTable
                  namePrefix={`${namePrefix}.options`}
                  productId={line.productId}
                  selectRef={selectRef}
                />
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  const renderPriceCell = (price: number | undefined) => (price && !queryPrice.isLoading ? formatCurrency(price) : '-');

  return (
    <>
      {renderTableRowParent()}
      {renderTableRowChild()}
    </>
  );
};
export default ProductRow;
