import ReactGA from 'react-ga4';
import { env } from '../env';
import { getLocalUser } from '../services/auth.service';
import { Domain } from '../types';
import { getDomain } from './helper';

type EventName =
  | 'click_login'
  | 'click_quotation'
  | 'click_sales_order'
  | 'click_serial_number'
  | 'click_shipment'
  | 'show_serial_numbers'
  | 'download_sales_invoice'
  | 'download_credit_note'
  | 'search_serial_number';

const gaOptions = {
  debug_mode: env.REACT_APP_ENVIRONMENT === 'Dev',
};

const initialize = () => {
  const gaId = getDomain() === Domain.firetrol ? env.REACT_APP_GA_ID_FIRETROL : env.REACT_APP_GA_ID_TORNATECH;
  if (gaId) {
    ReactGA.initialize(gaId, { gaOptions });
    return true;
  }

  return false;
};

const sendEvent = (name: EventName, params?: any) => {
  if (ReactGA.isInitialized) {
    const user = getLocalUser();
    ReactGA.event(name, {
      ...params,
      ...gaOptions,
      user_id: user?.id,
      user_name: user?.username,
      timestamp: +new Date(),
    });
  }
};

const sendPageView = (page: string) => {
  if (ReactGA.isInitialized) {
    const user = getLocalUser();
    ReactGA.send({ hitType: 'pageview', page, ...gaOptions, userId: user?.id });
  }
};

export default { initialize, sendEvent, sendPageView };
