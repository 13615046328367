import { ArrowDropDown, ArrowDropUp, Info } from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import theme, { sxHover } from '../../style/theme';
import { SalesOrder, SalesOrderLine, WorkOrder } from '../../types';
import Analytics from '../../utils/analytics';
import { formatCurrency, getSum } from '../../utils/helper';
import ProductDrawer from '../Dashboard/ProductDrawer';
import SearchInput from '../FormControls/SearchInput';

const style = {
  header: {
    padding: '0px 20px 0px 20px',
    marginTop: -2,
    marginBottom: 2,
  },
  headerTitle: {
    paddingTop: 3,
  },
  openRowButton: {
    marginTop: '10px',
    width: 'fit-content',
    marginBottom: '10px',
    color: theme.palette.grey[400],
  },
  parentRow: {
    '& td': {
      borderBottom: 'none',
      paddingBottom: '0px',
    },
  },
  serialNumberRow: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  serialNumberContainer: {
    paddingBottom: '32px',
  },
};

type Props = {
  salesOrder: SalesOrder;
};

const SalesOrderLineDetails = ({ salesOrder }: Props) => {
  const { lineDetails } = salesOrder;
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [data, setData] = useState<SalesOrderLine[]>([]);
  const [openedProduct, setOpenedProduct] = useState<SalesOrderLine | null>(null);
  const [openedIndexesSN, setOpenedIndexesSN] = React.useState<number[]>([]);
  const navigate = useNavigate();

  useEffect(() => setData(lineDetails), [lineDetails]);

  const onSearchTextChange = (keyword: string) => {
    const lowerKeyword = keyword.toLowerCase();
    const filterData = lineDetails.filter(
      (line) =>
        line.customerReference.toLowerCase().includes(lowerKeyword) ||
        line.serialNumbers.some(({ serialNumber }) => serialNumber.toLowerCase().includes(lowerKeyword)) ||
        line.productCode.toLowerCase().includes(lowerKeyword),
    );
    setData(filterData);
  };

  const isSerialNumbersOpen = (index: number) => openedIndexesSN.includes(index);

  const toggleSerialNumbersOpen = (index: number) => {
    const openedIndex = [...openedIndexesSN];
    if (isSerialNumbersOpen(index)) {
      openedIndex.splice(openedIndex.indexOf(index), 1);
    } else {
      openedIndex.push(index);
      Analytics.sendEvent('show_serial_numbers', { sales_order_no: salesOrder.details.id });
    }
    setOpenedIndexesSN(openedIndex);
  };

  const openSearchDocumentDrawer = ({ serialNumber }: WorkOrder) => {
    navigate(location.pathname, { state: { serialNumber }, replace: true });
    Analytics.sendEvent('click_serial_number', { serial_number: serialNumber });
  };

  const renderProductDrawer = () =>
    openedProduct && (
      <ProductDrawer
        isOpen={!!openedProduct}
        onClose={() => setOpenedProduct(null)}
        productCode={openedProduct.productCode}
        description={openedProduct.description}
        options={openedProduct.options}
      />
    );

  const renderTotal = () => (
    <Typography textAlign="end" variant="h2">
      {t('sales-orders:lineDetails.total', { total: formatCurrency(getSum(data, 'totalPrice')) })}
    </Typography>
  );

  const renderCollapseButton = (index: number) => {
    const isOpen = isSerialNumbersOpen(index);
    return (
      <Button
        disabled={data[index].serialNumbers.length === 0}
        disableFocusRipple
        sx={[style.openRowButton, sxHover]}
        onClick={() => toggleSerialNumbersOpen(index)}
        endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        <Typography letterSpacing={0} textTransform="uppercase" variant="buttonSmall">
          {isOpen ? t(`sales-orders:lineDetails.hideSerialNumbers`) : t(`sales-orders:lineDetails.showSerialNumbers`)}
        </Typography>
      </Button>
    );
  };

  const renderSerialNumbersRow = ({ serialNumbers }: SalesOrderLine, index: number) => (
    <TableRow>
      <TableCell sx={style.serialNumberRow} />
      <TableCell sx={style.serialNumberRow} colSpan={8}>
        {renderCollapseButton(index)}
        <Collapse in={isSerialNumbersOpen(index)} timeout="auto" unmountOnExit>
          <Box sx={style.serialNumberContainer}>
            {serialNumbers.map((s, i) => (
              <Button key={i} onClick={() => openSearchDocumentDrawer(s)} disableFocusRipple>
                <Typography variant="link">{s.serialNumber}</Typography>
                {serialNumbers.length - 1 !== i && <Typography variant="paragraphSmall">,</Typography>}
              </Button>
            ))}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  const renderTable = () => (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('sales-orders:lineDetails.lineNumber')}</TableCell>
            <TableCell>{t('sales-orders:lineDetails.productCode')}</TableCell>
            <TableCell>{t('sales-orders:lineDetails.customerReference')}</TableCell>
            <TableCell align="center">{t('sales-orders:lineDetails.orderQty')}</TableCell>
            <TableCell align="center">{t('sales-orders:lineDetails.balanceQty')}</TableCell>
            <TableCell align="right">{t('sales-orders:lineDetails.expShipDate')}</TableCell>
            <TableCell align="right">{t('common:product.unitPrice')}</TableCell>
            <TableCell align="right">{t('common:product.totalPrice')}</TableCell>
            <TableCell align="right">{t('common:actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((line, i) => (
            <React.Fragment key={i}>
              <TableRow sx={style.parentRow}>
                <TableCell>{line.lineNumber}</TableCell>
                <TableCell>{line.productCode}</TableCell>
                <TableCell>{line.customerReference}</TableCell>
                <TableCell align="center">{line.orderQty}</TableCell>
                <TableCell align="center">{line.balanceQty}</TableCell>
                <TableCell align="right">{line.shippingDate}</TableCell>
                <TableCell align="right">{formatCurrency(line.unitPrice)}</TableCell>
                <TableCell align="right">{formatCurrency(line.totalPrice)}</TableCell>
                <TableCell align="right">
                  <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
                    <IconButton onClick={() => setOpenedProduct(line)} size="small">
                      <Info sx={sxHover} htmlColor={palette.grey[400]} fontSize="small" />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
              {renderSerialNumbersRow(line, i)}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Stack spacing={2} sx={style.header}>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4} lg={6} xl={8} sx={style.headerTitle}>
          <Typography variant="h2">{t('sales-orders:lineDetails.title')}</Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={6} xl={4} alignItems="center">
          <SearchInput
            variant="outlinedWhite"
            onClearText={() => onSearchTextChange('')}
            placeholder={t('sales-orders:lineDetails.searchPlaceholder')}
            onChangeText={onSearchTextChange}
          />
        </Grid>
      </Grid>
      {renderTable()}
      {renderTotal()}
      {renderProductDrawer()}
      <Divider />
    </Stack>
  );
};

export default SalesOrderLineDetails;
