import { Box, Grid } from '@mui/material';
import React from 'react';
import theme from '../../style/theme';

const style = {
  left: {
    minHeight: '100%',
    backgroundColor: theme.palette.common.white,
    borderRight: '2px solid ' + theme.palette.grey[200],
    padding: '10px',
    paddingTop: '25px',
    paddingBottom: 0,
  },
  right: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    position: 'relative',
  },
  rightContent: {
    padding: '30px 0px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
  },
};

type Props = {
  leftComponent: JSX.Element;
  rightComponent: JSX.Element;
};

const DashboardBox = ({ leftComponent, rightComponent }: Props) => {
  return (
    <Grid container flexGrow={1}>
      <Grid display="flex" flexDirection="column" item xs={12} md={4} xl={3} sx={style.left}>
        {leftComponent}
      </Grid>
      <Grid item xs={12} md={8} xl={9} sx={style.right}>
        <Box sx={style.rightContent}>{rightComponent}</Box>
      </Grid>
    </Grid>
  );
};

export default DashboardBox;
