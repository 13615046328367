import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextFieldProps, useTheme } from '@mui/material';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import Input from './Input';

type Props = {
  onChangeText: (value: string) => void;
  onClearText: () => void;
  setActiveColor?: boolean;
  variant?: 'outlined' | 'outlinedWhite';
} & Omit<TextFieldProps, "variant">;

export default React.forwardRef((props: Props, ref) => {
  const { onChangeText, onClearText, placeholder, setActiveColor, variant = 'outlined' } = props;
  const { components } = useTheme();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 1000);
  const inputRef = useRef<HTMLInputElement>(null);
  const isChangeTextMounted = useRef(false);
  const stableProps = useRef({ onChangeText });

  useEffect(() => {
    if (isChangeTextMounted.current) {
      stableProps.current.onChangeText(debouncedSearchText);
    }
    isChangeTextMounted.current = true;
  }, [debouncedSearchText]);

  useImperativeHandle(ref, () => ({
    setText: (text: string, triggerChange = false) => {
      setSearchText(text);
      if (debouncedSearchText === text && triggerChange) {
        stableProps.current.onChangeText(debouncedSearchText);
      }
    },
  }));

  const onClearSearchPress = () => {
    inputRef.current?.focus();
    //bypass onChangeText call
    isChangeTextMounted.current = false;
    setSearchText('');
    onClearText();
  };

  const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  return (
    <Input
      label=""
      inputRef={inputRef}
      autoComplete="off"
      placeholder={placeholder}
      value={searchText}
      variant={variant}
      onChange={onSearchTextChange}
      InputProps={{
        ...components?.MuiTextField?.defaultProps?.InputProps,
        style: {
          ...components?.MuiTextField?.defaultProps?.InputProps?.style,
          ...props.InputProps?.style,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search color={setActiveColor ? 'primary' : 'inherit'} />
          </InputAdornment>
        ),
        endAdornment: searchText && (
          <InputAdornment position="end">
            <IconButton onClick={() => onClearSearchPress()} edge="end">
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></Input>
  );
});
