import { Logout, Person, Settings } from '@mui/icons-material';
import { Divider, Link, ListItemIcon, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import routes from '../../routes';
import { ExpandIconMenuProps, User } from '../../types';
import { formatUserFullName } from '../../utils/helper';
import { IconMenu } from './IconMenu';
import { PopupMenu } from './PopupMenu';

const style = {
  userInfoBox: {
    marginTop: 1,
    marginBottom: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    pointerEvents: 'none',
    width: 300,
  },
};

export function Profile({ anchorElement, setAnchor }: ExpandIconMenuProps) {
  const { palette } = useTheme();
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  const handleIconMenuPress = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(isActive() ? null : event.currentTarget);
  };

  const handleCloseUserMenu = () => setAnchor(null);

  const isActive = () => !!anchorElement;

  return (
    <Box flexGrow={0}>
      <IconMenu isActive={isActive()} onClick={handleIconMenuPress} icon={Person} />
      <PopupMenu anchorEl={anchorElement} open={isActive()} onClose={handleCloseUserMenu} onClick={handleCloseUserMenu}>
        <MenuItem sx={style.userInfoBox}>
          <Stack spacing={1}>
            <Typography variant="h3">{formatUserFullName(user as User)}</Typography>
            <Typography variant="caption">{user?.username}</Typography>
          </Stack>
        </MenuItem>
        <MenuItem component={Link} href={routes.settings.path}>
          <ListItemIcon>
            <Settings htmlColor={palette.grey[400]} fontSize="medium" />
          </ListItemIcon>
          <Typography color={palette.grey[400]} variant="paragraphSmall" textTransform="none" letterSpacing={0}>
            {t('auth:settings.title')}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout htmlColor={palette.grey[400]} fontSize="medium" />
          </ListItemIcon>
          <Typography color={palette.grey[400]} variant="paragraphSmall">
            {t('auth:logout')}
          </Typography>
        </MenuItem>
      </PopupMenu>
    </Box>
  );
}
