import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { SalesOrder, UserPlant } from '../types';
import { payment, salesOrders as salesOrdersApi, user } from '../api';
import { Elements } from '@stripe/react-stripe-js';
import { Box, Button, CircularProgress, Container } from '@mui/material';
import PaymentForm from '../components/Payment/PaymentForm';
import { colors } from '../style/theme';
import routes from '../routes';
import { getCurrentLanguage, getStripePublicKey } from '../utils/helper';
import { loadStripe } from '@stripe/stripe-js';

export function Payment() {
  const [isLoading, setLoading] = useState(false);
  const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [stripeToken, setStripeToken] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const style = {
    container: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    title: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    cancelButton: {
      color: colors.primary,
    },
  };

  const createClientSecret = async (accountId: string, data: SalesOrder) => {
    const resp = await payment.createIntent(accountId, data?.details.id);
    if (resp.data.client_secret) {
      setClientSecret(resp.data.client_secret);
    }
  };

  const findCustomerById = (plants: UserPlant[], customerAccountId: string) => {
    for (const plant of plants) {
      const customer = plant.customerAccounts.find((customer) => {
         return customer.id === customerAccountId;
      });
      if (customer) {
        return customer;
      }
    }
    return undefined;
  }

  const goBack = useCallback(() => {
    if (id) navigate(routes.salesOrder.path.replace(':id', id), { replace: true });
  }, [id, navigate]);

  useEffect(() => {
    (async () => {
      if (id) {
        let data: SalesOrder | null = null;
        try {
          setLoading(true);
          setSalesOrder(null);
          data = (await salesOrdersApi.get(id)).data;
        } finally {
          setSalesOrder(data);
          const results = (await user.getPlants()).data.plants;
          if (data) {
            if (!data.details.ccAuthorized) {
              goBack();
            } else {
              const customer = findCustomerById(results, data?.details.customerAccountId);

              if (customer && customer.ccAuthorized && customer.stripeAccountId !== '') {
                setStripeToken(getStripePublicKey(customer.stripeAccountId));
                createClientSecret(customer.stripeAccountId, data as SalesOrder);
              } else {
                goBack();
              }
            }
          }
          setLoading(false);
        }
      }
    })();
  }, [goBack, id]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container maxWidth="lg" style={style.container}>
      <Button style={style.cancelButton} onClick={goBack}>
        {t('common:cancel')}
      </Button>
      {clientSecret !== '' && salesOrder && stripeToken !== '' && (
        <Elements stripe={loadStripe(stripeToken)} options={{ clientSecret, locale: getCurrentLanguage() }}>
          <PaymentForm salesOrder={salesOrder} />
        </Elements>
      )}
    </Container>
  );
}
