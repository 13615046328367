import { Box, CircularProgress, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { quotations as quotationsApi } from '../api';
import {
  DashboardBox,
  DashboardSearch,
  IconTextPlaceholder,
  QuotationHeader,
  QuotationLineDetails,
  QuotationListItem,
} from '../components';
import routes from '../routes';
import { BaseDashboardSearchQuery, Quotation, QuotationStatus, SearchQuotation } from '../types';
import Analytics from '../utils/analytics';
import { LIMIT_QUOTATIONS } from '../utils/constants';

export function Quotations() {
  const { t } = useTranslation();
  const [quotations, setQuotations] = useState<SearchQuotation[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [currentQuotation, setCurrentQuotation] = useState<Quotation | null>(null);
  const currentParamId = useRef<string | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const searchQuotations = async (query: BaseDashboardSearchQuery) => {
    setQuotations([]);
    if (query.resetPagination) {
      setTotalCount(0);
    }
    const { data } = await quotationsApi.search(LIMIT_QUOTATIONS, query.offset, query.keywords, query.status);
    setQuotations(data.data);
    setTotalCount(data.meta?.totalCount ?? 0);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        let data: Quotation | null = null;
        try {
          setLoading(true);
          setCurrentQuotation(null);
          data = (await quotationsApi.get(id)).data;
        } finally {
          if (!currentParamId.current || currentParamId.current === id) {
            setCurrentQuotation(data);
            setLoading(false);
            currentParamId.current = null;
          }
        }
      }
    })();
  }, [id]);

  const onQuotationClick = ({ id }: { id: string }) => {
    Analytics.sendEvent('click_quotation', { quotation_no: id });
    currentParamId.current = id;
    navigate(routes.quotation.path.replace(':id', id), { replace: true });
  };

  const renderQuotationItem = (quotation: SearchQuotation, isSelected: boolean) => (
    <QuotationListItem quotation={quotation} isSelected={isSelected} />
  );

  const renderDashboardSearch = () => {
    return (
      <DashboardSearch
        namespace="quotations"
        renderItem={renderQuotationItem}
        isSelected={(quotation) => quotation.id === id}
        data={quotations}
        total={totalCount}
        limit={LIMIT_QUOTATIONS}
        onItemClick={onQuotationClick}
        statuses={Object.values(QuotationStatus)}
        refresh={searchQuotations}
        createHref={routes.createQuotation.path}
      />
    );
  };

  const renderDashboardContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    } else if (currentQuotation) {
      return (
        <Box>
          <QuotationHeader quotation={currentQuotation} />
          <Divider />
          <QuotationLineDetails quotation={currentQuotation} />
        </Box>
      );
    } else if (id) {
      return <IconTextPlaceholder title={t('quotations:details.notFound', { id })} />;
    } else {
      return (
        <IconTextPlaceholder
          title={t('quotations:details.noSelectedTitle')}
          description={t('quotations:details.noSelectedDescription')}
        />
      );
    }
  };

  return <DashboardBox leftComponent={renderDashboardSearch()} rightComponent={renderDashboardContent()} />;
}
