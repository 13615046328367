import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationStatus, SearchQuotation } from '../../types';
import { formatCurrency } from '../../utils/helper';
import AlertIcon from '../Dashboard/AlertIcon';
import StatusChip from '../StatusChip';

type Props = {
  quotation: SearchQuotation;
  isSelected: boolean;
};

const QuotationListItem = ({ quotation, isSelected }: Props) => {
  const { quotationNumber, companyName, rfq, expiredDate, alert, status, total, revision } = quotation;
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <Stack width="100%">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Typography
          color={isSelected ? 'primary' : palette.grey[500]}
          fontWeight={isSelected ? 700 : 500}
          variant="paragraphSmall"
        >
          {quotationNumber + (revision ? `-${revision}` : '')}
        </Typography>
        <StatusChip
          color={status === QuotationStatus.Active ? 'active' : 'disabled'}
          text={t(`quotations:status.${status}`)}
        />
        <AlertIcon alertText={alert} size="small" />
      </Stack>
      <Stack>
        <Typography variant="caption">{companyName}</Typography>
        <Typography color={palette.grey[400]} variant="caption">
          {rfq}
        </Typography>
      </Stack>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color={palette.grey[400]} variant="caption">
          {t('quotations:list.expiration', { date: expiredDate })}
        </Typography>
        <Typography variant="paragraphSmall">{formatCurrency(total)}</Typography>
      </Box>
    </Stack>
  );
};

export default QuotationListItem;
