import { Autocomplete, Box, Stack, TextField, Typography, TypographyProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../style/theme';
import { SelectOption } from '../../types';

const style = {
  container: {
    paddingTop: '23px',
  },
  select: {
    height: '48px',
  },
};

type Props = {
  options: SelectOption[];
  onChange?: (value: string) => void;
  label: string;
  selectedValue?: string;
  labelVariant?: TypographyProps['variant'];
  inputWidth?: any;
  maxInputWidth?: string;
  disabled?: boolean;
};

const SearchSelect = ({
  options,
  onChange,
  label,
  selectedValue,
  labelVariant,
  inputWidth,
  maxInputWidth,
  disabled = false,
}: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<SelectOption | null>(null);
  const [inputValue, setInputValue] = useState(selectedValue);

  useEffect(() => {
    const currentOption = options.find((o) => o.value === selectedValue);
    setValue(currentOption ?? null);
    setInputValue(currentOption?.label ?? '');
  }, [selectedValue, options]);

  return (
    <Stack sx={style.container} spacing={1.5}>
      <Typography variant={labelVariant ?? 'label'}>{label}</Typography>
      <Box>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.value === value.value}
          autoHighlight
          disablePortal
          inputValue={inputValue}
          onInputChange={(_, value) => setInputValue(value)}
          getOptionLabel={(option) => option.label}
          options={options}
          sx={[style.select, { backgroundColor: theme.palette.common.white, width: inputWidth }]}
          noOptionsText={t('form:noOptions')}
          value={value}
          disabled={disabled}
          onChange={(_, v) => onChange && onChange(v?.value ?? '')}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  autoComplete: 'off',
                  style: {
                    height: '14px',
                    width: inputValue && !inputWidth ? inputValue.length + 2 + 'ch' : undefined,
                    maxWidth: maxInputWidth ? maxInputWidth : !inputWidth ? '150px' : undefined,
                  },
                },
              }}
            />
          )}
        />
      </Box>
    </Stack>
  );
};

export default SearchSelect;
