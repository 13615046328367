import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Analytics from '../utils/analytics';

const useAnalytics = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (Analytics.initialize()) {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      Analytics.sendPageView(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default useAnalytics;
