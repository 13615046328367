import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreationContext } from '../../../contexts/CreationContext';
import { getCountry } from '../../../services/countries.service';
import { Address } from '../../../types';

const style = {
  saveCheckbox: {
    marginLeft: '-10px',
  },
};

type Props = {
  address: Address;
  saveAddress?: boolean;
};

const AddressCard = ({ address, saveAddress }: Props) => {
  const { t } = useTranslation();
  const { quotation } = useContext(CreationContext);
  const [countryName, setCountryName] = useState<string>('');

  useEffect(() => {
    (async () => setCountryName((await getCountry(address.country))?.name ?? address.country))();
  }, [address.country]);

  const formatCity = () => {
    return `${address.city ? address.city + ',' : ''} ${address.state} ${address.zipCode}`;
  };

  const safeLine = (value: string) => {
    return value ? value : '-';
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Typography variant="paragraphBigBold">{safeLine(address.consigneeName)}</Typography>
            <Typography variant="paragraphBig">{safeLine(address.addressLine1)}</Typography>
            {!!address.addressLine2 && <Typography variant="paragraphBig">{address.addressLine2}</Typography>}
            {!!address.addressLine3 && <Typography variant="paragraphBig">{address.addressLine3}</Typography>}
            <Typography variant="paragraphBig">{formatCity()}</Typography>
            <Typography variant="paragraphBig">{countryName}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1}>
            <Typography variant="paragraphBig">{address.contactPerson}</Typography>
            <Typography variant="paragraphBig">{address.contactPhoneNumber}</Typography>
            {!address.addressId && !quotation && (
              <FormControlLabel
                disabled
                control={<Checkbox readOnly disabled sx={style.saveCheckbox} checked={saveAddress} />}
                label={t('form:step2.saveAddress') as string}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddressCard;
