import { RequireAuth, RequireUnauth } from './components';
import {
  CreateQuotation,
  CreateSalesOrder,
  ForgotPassword,
  Home,
  Login,
  NotFound,
  Quotations,
  RequestAccess,
  SalesOrders,
  Settings,
  Payment,
} from './pages';

export default {
  home: {
    path: '/',
    element: <Home />,
  },
  quotations: {
    path: '/quotations',
    element: (
      <RequireAuth>
        <Quotations />
      </RequireAuth>
    ),
  },
  quotation: {
    path: '/quotations/:id',
    element: (
      <RequireAuth>
        <Quotations />
      </RequireAuth>
    ),
  },
  login: {
    path: '/login',
    element: (
      <RequireUnauth>
        <Login />
      </RequireUnauth>
    ),
  },
  requestAccess: {
    path: '/request-access',
    element: (
      <RequireUnauth>
        <RequestAccess />
      </RequireUnauth>
    ),
  },
  forgotPassword: {
    path: '/forgot-password',
    element: (
      <RequireUnauth>
        <ForgotPassword />
      </RequireUnauth>
    ),
  },
  salesOrders: {
    path: '/sales-orders',
    element: (
      <RequireAuth>
        <SalesOrders />
      </RequireAuth>
    ),
  },
  salesOrder: {
    path: '/sales-orders/:id',
    element: (
      <RequireAuth>
        <SalesOrders />
      </RequireAuth>
    ),
  },
  settings: {
    path: '/settings',
    element: (
      <RequireAuth>
        <Settings />
      </RequireAuth>
    ),
  },
  notFound: {
    path: '/404',
    element: <NotFound />,
  },
  createQuotation: {
    path: '/quotations/new',
    element: (
      <RequireAuth>
        <CreateQuotation />
      </RequireAuth>
    ),
  },
  createSalesOrder: {
    path: '/sales-orders/new',
    element: (
      <RequireAuth>
        <CreateSalesOrder />
      </RequireAuth>
    ),
  },
  payment: {
    path: '/payment/:id',
    element: (
      <RequireAuth>
        <Payment />
      </RequireAuth>
    ),
  },
};
