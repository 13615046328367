export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^[0-9#+]*$/;
export const LIMIT_NOTIFICATIONS = 10;
export const LIMIT_OPTIONS = 10;
export const LIMIT_QUOTATIONS = 10;
export const LIMIT_SALES_ORDERS = 10;
export const NO_BACK_KEY = 'default';
export const FETCH_COUNT_NOTIFICATION_INTERVAL = 60000;
export const DATE_REGEX = /^[12][0-9]{3}[--.](?:0[1-9]|1[012])[--.](?:0[1-9]|[12][0-9]|3[01])\b/;
export const MAX_FILE_SIZE_MB = 100;