import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { settings as settingsApi } from '../api';
import { ConfirmationModal } from '../components';
import { LanguageCode, NotificationType, Settings as SettingsType } from '../types';
import { NO_BACK_KEY } from '../utils/constants';
import { areArrayEquals, changeLanguage, getCurrentLanguage } from '../utils/helper';

const style = {
  container: {
    padding: 5,
  },
  section: {
    marginTop: 7,
    marginBottom: 5,
  },
  listItem: {
    padding: 0,
    paddingTop: 3,
    paddingBottom: 3,
  },
  listItemRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionButton: {
    height: 42,
    borderRadius: '5px',
  },
  loading: {
    position: 'absolute',
  },
};

export function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const defaultSettings = {
    languageCode: getCurrentLanguage(),
    notifications: [],
    notifyEmail: false,
    notifyPortal: false,
  };
  const [settings, setSettings] = useState<SettingsType>(defaultSettings);
  const [originalSettings, setOriginalSettings] = useState<SettingsType>(defaultSettings);
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    settingsApi
      .get()
      .then(({ data }) => {
        if (data) {
          setSettings(data);
          setOriginalSettings(data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const onSavePress = async () => {
    try {
      setSaving(true);
      await settingsApi.update(settings);
      await changeLanguage(settings.languageCode);
      setOriginalSettings(settings);
    } finally {
      setSaving(false);
    }
  };

  const onCancelPress = () => {
    if (!location.key || location.key === NO_BACK_KEY) {
      setSettings(originalSettings);
    } else if (isModified()) {
      setConfirmationVisible(true);
    } else {
      navigate(-1);
    }
  };

  const onLanguageChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSettings((prev) => ({ ...prev, languageCode: ev.target.value as LanguageCode }));

  const onNotifyEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSettings((prev) => ({ ...prev, notifyEmail: ev.target.checked }));

  const onNotifyPortalChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSettings((prev) => ({ ...prev, notifyPortal: ev.target.checked }));

  const updateNotification = (notification: NotificationType, ev: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => {
      const notifications = [...prev.notifications];
      if (ev.target.checked) {
        notifications.push(notification);
      } else {
        notifications.splice(notifications.indexOf(notification), 1);
      }
      return { ...prev, notifications };
    });
  };

  const isNotificationSelected = (type: NotificationType) => settings.notifications.includes(type);

  const isLanguageSelected = (code: LanguageCode) => settings.languageCode === code;

  const isModified = () =>
    settings.languageCode != originalSettings.languageCode ||
    settings.notifyEmail != originalSettings.notifyEmail ||
    settings.notifyPortal != originalSettings.notifyPortal ||
    !areArrayEquals(settings.notifications, originalSettings.notifications);

  const renderNotificationSettings = () => (
    <Box sx={style.section}>
      <Typography variant="h2">{t('notifications:title')}</Typography>
      <List>
        <ListItem sx={[style.listItem, style.listItemRow]} divider>
          <Typography variant="paragraphBig">{t('notifications:settings.how')}</Typography>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox onChange={onNotifyEmailChange} checked={settings.notifyEmail} />}
              label={t('common:user.email') as string}
            />
            <FormControlLabel
              control={<Checkbox onChange={onNotifyPortalChange} checked={settings.notifyPortal} />}
              label={t('common:portal') as string}
            />
          </FormGroup>
        </ListItem>
        <ListItem sx={style.listItem} divider>
          <Stack spacing={2}>
            <Typography variant="paragraphBig">{t('notifications:settings.what')}</Typography>
            <FormGroup row>
              {Object.values(NotificationType).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <Checkbox onChange={(ev) => updateNotification(type, ev)} checked={isNotificationSelected(type)} />
                  }
                  label={t(`notifications:type.${type}.title`) as string}
                />
              ))}
            </FormGroup>
          </Stack>
        </ListItem>
      </List>
    </Box>
  );

  const renderLanguageSettings = () => (
    <Box sx={style.section}>
      <Typography variant="h2">{t('auth:settings.language.title')}</Typography>
      <List>
        <ListItem sx={style.listItem} divider>
          <RadioGroup row>
            {Object.values(LanguageCode).map((code) => (
              <FormControlLabel
                key={code}
                value={code}
                control={<Radio onChange={onLanguageChange} checked={isLanguageSelected(code)} />}
                label={t(`auth:settings.language.${code}`) as string}
              />
            ))}
          </RadioGroup>
        </ListItem>
      </List>
    </Box>
  );

  const renderConfirmationModal = () => (
    <ConfirmationModal
      isVisible={isConfirmationVisible}
      onCancel={() => setConfirmationVisible(false)}
      onConfirm={() => navigate(-1)}
      title={t('auth:settings.confirmTitle')}
      description={t('auth:settings.confirmDescription')}
      confirmText={t('auth:settings.confirm')}
    />
  );

  return (
    <Container sx={style.container}>
      <Typography variant="h1">{t('auth:settings.title')}</Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={60} />
        </Box>
      ) : (
        <>
          {renderNotificationSettings()}
          {renderLanguageSettings()}
          <Stack direction="row" spacing={2}>
            <LoadingButton
              loading={isSaving}
              sx={style.actionButton}
              onClick={onSavePress}
              disabled={isSaving || !isModified()}
              variant="contained"
            >
              <Typography variant="buttonMedium">{t('auth:settings.action')}</Typography>
            </LoadingButton>
            <Button onClick={onCancelPress} variant="outlined">
              {t('common:cancel')}
            </Button>
          </Stack>
          {renderConfirmationModal()}
        </>
      )}
    </Container>
  );
}
