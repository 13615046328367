import { Box, Button, Divider } from '@mui/material';
import { OrderStatus, SalesOrder } from '../../types';
import PaymentMessage from './PaymentMessage';
import { useTranslation } from 'react-i18next';
import { colors } from '../../style/theme';

type Props = {
  userCCAuthorized: boolean;
  currentSalesOrder?: SalesOrder;
};

const style = {
  payButtonContainer: {
    paddingBottom: '6px',
  },
  payButtonDisabled: {
    backgroundColor: colors.grey4,
  },
};

const PaymentButton = ({ userCCAuthorized, currentSalesOrder }: Props) => {
  const { t } = useTranslation();
  const disabled =
    !currentSalesOrder?.details.ccAuthorized ||
    currentSalesOrder?.details.paymentConfirmationId !== '' ||
    currentSalesOrder?.details.status !== OrderStatus.Open ||
    currentSalesOrder?.details?.ccDisabledMessage?.en !== '' ||
    currentSalesOrder?.details?.ccDisabledMessage?.fr !== '';
    
  if (userCCAuthorized && currentSalesOrder) {
    return (
      <>
        <Box display="flex" justifyContent="center" style={style.payButtonContainer}>
          <Button
            disabled={disabled}
            href={`/payment/${currentSalesOrder.details.id}`}
            style={disabled ? style.payButtonDisabled : {}}
            variant="containedSmall"
          >
            {t('common:payNow')}
          </Button>
        </Box>
        <PaymentMessage currentSalesOrder={currentSalesOrder} />
        <Divider />
      </>
    );
  }
  return <></>;
};

export default PaymentButton;
