import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const style = {
  container: {
    display: 'flex',
    height: '100%',
  },
};

export function NotFound() {
  const { t } = useTranslation();
  const { state }: { state: any } = useLocation();

  return (
    <Container sx={style.container}>
      <Stack width="100%" display="flex" justifyContent="center" alignItems="center" spacing={3}>
        <Typography fontSize={40} variant="h1">
          {t('common:notFound.title')}
        </Typography>
        <Typography variant="h1">{t('common:notFound.subtitle')}</Typography>
        {state?.path && <Typography variant="h2">{state?.path}</Typography>}
      </Stack>
    </Container>
  );
}
