import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { env } from './env';
import { LanguageCode, ShippingDateChange } from './types';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      ns: ['common', 'auth', 'quotations', 'sales-orders', 'notifications', 'form', 'payment'],
      load: 'languageOnly',
      fallbackLng: (code) => {
        const languageCode = code.split('-')[0] as LanguageCode;
        if (Object.values(LanguageCode).includes(languageCode)) {
          return [languageCode];
        }

        return [LanguageCode.en];
      },
      debug: env.REACT_APP_ENVIRONMENT === 'Dev',
      react: {
        useSuspense: true,
      },
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      i18n.services.formatter?.add('firstUpper', (value) => value.charAt(0).toUpperCase() + value.slice(1));
      i18n.services.formatter?.add('lineNumbers', ({ lineNumbers, productCodes }: ShippingDateChange) =>
        lineNumbers.length > 1 ? `s ${lineNumbers.join(',')}` : ` ${lineNumbers[0]} - ${productCodes[0]}`,
      );
      i18n.services.formatter?.add('indexValueAt', (index, _, options: string[]) => options[index]);
    },
  );

export default i18n;
