import { Menu, MenuProps } from '@mui/material';

const style = {
  menuPaper: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    borderRadius: 2,
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
};

export function PopupMenu(props: MenuProps) {
  return (
    <Menu
      disableEnforceFocus
      PaperProps={{ elevation: 0, sx: style.menuPaper }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    >
      {props.children}
    </Menu>
  );
}
