import { enCA, frCA } from 'date-fns/locale';
import i18n from '../i18n';
import { Domain, LanguageCode, User } from '../types';
import { env } from '../env';

export const formatUserFullName = (user: User) => `${user.firstName} ${user.lastName}`;

export const getDateLocale = (): Locale => {
  switch (getCurrentLanguage()) {
    case 'fr':
      return frCA;
    default:
      return enCA;
  }
};

export const getCurrentLanguage = () => {
  const language = i18n.language.split('-')[0] as LanguageCode;
  if (!Object.values(LanguageCode).includes(language)) {
    return LanguageCode.en;
  }
  return language;
};

export const changeLanguage = async (code: LanguageCode) => {
  if (code && getCurrentLanguage() != code) {
    await i18n.changeLanguage(code);
  }
};

export const distinctByProp = (array: any[], propertyName: string) => {
  return array.filter((value, index, self) => index === self.findIndex((t) => t[propertyName] === value[propertyName]));
};

export const areArrayEquals = (array1: any[], array2: any[]) => {
  return array1.length == array2.length && array1.every((element) => array2.indexOf(element) >= 0);
};

export const getDomain = () =>
  window.location.hostname.toLowerCase().includes(Domain.firetrol) ? Domain.firetrol : Domain.tornatech;

export const setFavicon = () => {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  favicon.href = `${getDomain()}.ico`;
};

export const isString = (value: any) => typeof value === 'string' || value instanceof String;

export const formatBytes = (bytes: number | undefined, decimals = 2) => {
  if (!bytes || bytes <= 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatCurrency = (amount: number) =>
  amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const getSum = (array: any[], propertyName: string) => {
  return array.reduce((a, b) => a + (b[propertyName] || 0), 0);
};

export const combineUrl = (baseUrl: string, relativeUrl: string) => {
  return baseUrl.replace(/\/$/, '') + '/' + relativeUrl.replace(/^\//, '');
};

export const bytesToMB = (bytes: number) => bytes / 1024 ** 2;

export const getFileExtension = (filename: string) => '.' + filename.split('.').pop();

export const safeParseFloat = (value: string | null | undefined) => {
  if (value) {
    const floatValue = parseFloat(value);
    return isNaN(floatValue) ? null : floatValue;
  }
  return null;
};

export const getStripePublicKey = (accountId: string) => {
  switch (accountId) {
    case env.REACT_APP_TORNATECH_RBC_USD_STRIPE_ACCOUNT_ID:
      return env.REACT_APP_TORNATECH_RBC_USD_STRIPE_API_PUBLIC_KEY
    case env.REACT_APP_TORNATECH_RBC_CAD_STRIPE_ACCOUNT_ID:
      return env.REACT_APP_TORNATECH_RBC_CAD_STRIPE_API_PUBLIC_KEY
    case env.REACT_APP_TORNATECH_CND_USD_STRIPE_ACCOUNT_ID:
      return env.REACT_APP_TORNATECH_CND_USD_STRIPE_API_PUBLIC_KEY
    case env.REACT_APP_FIRETROL_STRIPE_ACCOUNT_ID:
      return env.REACT_APP_FIRETROL_CND_USD_STRIPE_API_PUBLIC_KEY
    default:
      return env.REACT_APP_TORNATECH_RBC_CAD_STRIPE_API_PUBLIC_KEY
  }
}

export const decodeString = (string: string) => {
  const element = document.createElement("span");
  element.innerHTML = string;
  return element.innerText;
}

export const delay = (delayInMs: number) => {
  return new Promise(resolve => setTimeout(resolve, delayInMs));
};