import { Box, Button, Modal, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../style/theme';

const style = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '95%',
      sm: 400,
    },
    backgroundColor: theme.palette.common.white,
    boxShadow: 24,
    padding: 4,
    borderRadius: 2,
    maxHeight: '75%',
  },
  confirm: {
    height: 42,
    borderRadius: '5px',
  },
};

type Props = {
  isVisible: boolean;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  children?: JSX.Element | JSX.Element[];
  confirmText?: string;
  width?: number | { xs?: string | number; sm: string | number };
};

const BaseModal = (props: Props) => {
  const { isVisible, title, children, onConfirm, onCancel, confirmText, width } = props;
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <Modal open={isVisible} onClose={onCancel}>
      <Stack sx={{ ...style.container, width: width ?? style.container.width }} spacing={3}>
        <Typography variant="h2" color={palette.common.black}>
          {title}
        </Typography>
        <Box sx={{ overflow: 'auto' }}>{children}</Box>
        <Stack display="flex" justifyContent="flex-end" direction="row" spacing={2}>
          <Button onClick={onCancel} variant="outlined">
            {t('common:cancel')}
          </Button>
          <Button onClick={onConfirm} sx={style.confirm} variant="contained">
            <Typography variant="buttonMedium">{confirmText ?? t('common:confirm')}</Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default BaseModal;
