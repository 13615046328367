import { InfoOutlined } from '@mui/icons-material';
import { Box, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { isString } from '../../utils/helper';

const style = {
  value: {
    overflowWrap: 'anywhere',
  },
};

type TitleValue = {
  title: string;
  value: string | number | React.ReactElement;
  gridSize: number;
  isCentered?: boolean;
  tooltip?: string;
};

type Props = {
  data: TitleValue[];
};

const DashboardItemDetails  = ({ data }: Props) => {
  const { palette } = useTheme();
  const maxHeightTitleRef = useRef<Array<HTMLElement>>([]);
  const [maxHeightTitle, setMaxHeightTitle] = useState<number | null>(null);

  useLayoutEffect(() => {
    const maxHeight = Math.max(...maxHeightTitleRef.current.map((e) => e.clientHeight), 0);
    setMaxHeightTitle(maxHeight);
  }, []);

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {data.map(({ title, value, isCentered, gridSize, tooltip }, i) => (
        <Grid key={title} xs={6} sm={4} xl={gridSize} item>
          <Stack spacing={2} display="flex" justifyContent="center">
            <Box
              height={maxHeightTitle ?? undefined}
              ref={(el: HTMLElement) => (maxHeightTitleRef.current[i] = el)}
              display="flex"
              alignItems="flex-end"
              justifyContent={isCentered ? 'center' : undefined}
            >
              <Stack spacing={0.5} alignItems="flex-end" direction="row">
                <Typography whiteSpace="pre-line" variant="menuItem" color={palette.grey[300]}>
                  {title}
                </Typography>
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <InfoOutlined htmlColor={palette.grey[300]} fontSize="small" />
                  </Tooltip>
                )}
              </Stack>
            </Box>
            <Box display="flex" justifyContent={isCentered ? 'center' : undefined}>
              {isString(value) ? (
                <Typography whiteSpace="pre-line" sx={style.value} fontWeight={400} variant="paragraphSmall">
                  {value}
                </Typography>
              ) : (
                value
              )}
            </Box>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardItemDetails;
