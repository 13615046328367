import { Add, FilterAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme, { sxHover } from '../../style/theme';
import { BaseDashboardSearchQuery } from '../../types';
import SearchInput from '../FormControls/SearchInput';
import IconTextPlaceholder from '../IconTextPlaceholder';
import DashboardTablePagination from './DashboardTablePagination';

const style = {
  expand: {
    marginTop: 1,
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
  },
  innerList: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
  },
  noResults: {
    marginTop: 3,
    textAlign: 'center',
    height: 'auto',
  },
  search: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  selectedListItem: {
    backgroundColor: theme.palette.grey[100],
  },
  pagination: {
    borderTop: '1px solid ' + theme.palette.grey[200],
    '& button': {
      color: theme.palette.grey[400],
    },
  },
  createButton: {
    minWidth: '35px',
    width: '35px',
    height: '35px',
    verticalAlign: 'top',
    marginLeft: '10px',
  },
};

export type Props<T> = {
  namespace: string;
  data: ReadonlyArray<T>;
  total: number;
  limit: number;
  statuses: string[];
  onItemClick: (item: T) => void;
  createHref: string;
  renderItem: (item: T, isSelected: boolean) => JSX.Element;
  refresh: (query: BaseDashboardSearchQuery) => Promise<void>;
  isSelected: (item: T) => boolean;
};

const defaultSearchQuery: BaseDashboardSearchQuery = { keywords: '', status: '', offset: 0, resetPagination: true };

export function DashboardSearch(props: Props<any>) {
  const { namespace, renderItem, data, total, limit, statuses, onItemClick, isSelected, createHref } = props;
  const { palette } = useTheme();
  const onRefresh = useRef(props.refresh);

  const { t } = useTranslation();
  const [query, setQuery] = useState<BaseDashboardSearchQuery>(defaultSearchQuery);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onRefresh.current(query).finally(() => setLoading(false));
  }, [query]);

  const getPaginationButtonProps = () => (isLoading ? { disabled: isLoading } : undefined);

  const onStatusChange = (ev: SelectChangeEvent<string>) => {
    setQuery(({ keywords }) => ({ ...defaultSearchQuery, status: ev.target.value, keywords }));
  };

  const onSearchTextChange = (keywords: string) => {
    setQuery(({ status }) => ({ ...defaultSearchQuery, keywords, status }));
  };

  const onPageChange = (offset: number) => {
    setQuery((p) => ({ ...p, offset, resetPagination: false }));
  };

  const renderLoading = () => (
    <Box sx={style.noResults} display="flex" justifyContent="center">
      <CircularProgress size={50} />
    </Box>
  );

  const renderEmptyResults = () => (
    <IconTextPlaceholder containerSx={style.noResults} title={t(`${namespace}:list.emptyTitle`)} />
  );

  const renderSelect = () => (
    <Select
      disableUnderline
      displayEmpty
      variant="standard"
      value={query.status}
      onChange={onStatusChange}
      renderValue={(selected) =>
        selected ? (
          <Typography sx={sxHover} color={'primary'} textTransform="uppercase" variant="buttonSmall">
            {t(`${namespace}:status.${selected}`)}
          </Typography>
        ) : (
          <FilterAlt htmlColor={palette.grey[400]} />
        )
      }
    >
      <MenuItem value="">
        <em>{t('quotations:status.All')}</em>
      </MenuItem>
      {statuses.map((status) => (
        <MenuItem key={status} value={status}>
          {t(`${namespace}:status.${status}`)}
        </MenuItem>
      ))}
    </Select>
  );

  const renderBaseListButton = (item: any, index: number) => {
    const selected = isSelected(item);
    return (
      <ListItemButton sx={selected ? style.selectedListItem : null} key={index} onClick={() => onItemClick(item)}>
        {renderItem(item, selected)}
      </ListItemButton>
    );
  };

  const renderResults = () => <List sx={style.innerList}>{data.map(renderBaseListButton)}</List>;

  const renderContent = () => {
    if (isLoading) {
      return renderLoading();
    } else if (data?.length === 0) {
      return renderEmptyResults();
    } else if (data?.length > 0) {
      return renderResults();
    }
    return null;
  };

  const renderCreate = () => (
    <Button href={createHref} sx={style.createButton} variant="contained">
      <Add />
    </Button>
  );

  return (
    <>
      <Box flexGrow={0} sx={style.search}>
        <Box alignItems="center" display="flex" justifyContent="space-between" flexDirection="row">
          <Typography variant="h3">{t(`${namespace}:list.title`)}</Typography>
          <Box flexDirection="row" alignItems="center" justifyContent="center">
            {statuses.length > 0 && renderSelect()}
            {renderCreate()}
          </Box>
        </Box>
        <SearchInput
          placeholder={t(`${namespace}:list.search`)}
          onChangeText={onSearchTextChange}
          onClearText={() => onSearchTextChange('')}
        />
      </Box>
      <Box sx={style.expand}>{renderContent()}</Box>
      <DashboardTablePagination
        count={total}
        page={Math.max(0, Math.ceil(query.offset / limit))}
        labelDisplayedRows={(pagination) => (
          <Typography color={palette.grey[400]} variant="paragraphSmall">
            {t(`${namespace}:list.pagination`, { pagination })}
          </Typography>
        )}
        onPageChange={(ev, page) => onPageChange(page * limit)}
        rowsPerPage={limit}
        rowsPerPageOptions={[]}
        backIconButtonProps={getPaginationButtonProps()}
        nextIconButtonProps={getPaginationButtonProps()}
        namespace={namespace}
      />
    </>
  );
}
