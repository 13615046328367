import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBack, ArrowForward, Check } from '@mui/icons-material';
import { Box, Button, Container, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import theme from '../../style/theme';
import { typography } from '../../style/typography';
import { CreationForm } from '../../types';
import WizardCancellationModal from './WizardCancellationModal';
import { WizardStepProps } from './WizardStep';

type WizardSteps = React.ReactElement<WizardStepProps>[];

type Props = {
  initialValues: any;
  onSubmit: (values: any) => void;
  children: WizardSteps | React.ReactElement<WizardStepProps>;
  title: string;
  state: 'success' | 'error' | 'loading' | undefined;
};

const style = {
  container: {
    marginTop: '20px',
    height: '100%',
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  nextButton: {
    marginLeft: '15px',
  },
  cancelButton: {
    color: theme.palette.primary.main,
    ...typography.buttonSmall,
    letterSpacing: 0,
    marginBottom: '10px',
    alignSelf: 'start',
  },
  stepTitleContainer: {
    marginTop: '25px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '25px',
  },
  bottomStepButton: {
    paddingBottom: '120px',
    paddingTop: '20px',
  },
};

const Wizard = ({ children, initialValues, onSubmit, title, state }: Props) => {
  const { t } = useTranslation();

  const [stepNumber, setStepNumber] = useState(0);
  const steps = (React.Children.toArray(children) as WizardSteps).filter((child) => !!child.props.validationSchema);
  const navigate = useNavigate();

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const methods = useForm<CreationForm>({
    resolver: yupResolver(step.props.validationSchema),
    defaultValues: initialValues,
  });

  const onNextClick = () => {
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const onPreviousClick = () => {
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const onSubmitNext = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {termsAccepted, ...serverValues} = values;
    if (isLastStep) {
      return onSubmit(serverValues);
    } else {
      onNextClick();
    }
  };

  const handleCancel = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const renderStepButton = () => (
    <Box alignItems="center" display="flex" justifyContent="center" flexDirection="row">
      {stepNumber > 0 && (
        <Button onClick={() => onPreviousClick()} startIcon={<ArrowBack fontSize="large" />} variant="outlined">
          {t('form:previous')}
        </Button>
      )}
      <Button
        sx={style.nextButton}
        onClick={methods.handleSubmit(onSubmitNext)}
        endIcon={isLastStep ? <Check /> : <ArrowForward />}
        variant="containedSmall"
      >
        {isLastStep ? t('form:finish') : t('form:next')}
      </Button>
    </Box>
  );

  return (
    <Container sx={style.container}>
      <FormProvider {...methods}>
        <form style={style.formContainer as React.CSSProperties}>
          <WizardCancellationModal isSuccess={state === 'success'} />
          <Button variant="text" sx={style.cancelButton} onClick={handleCancel}>
            {t('common:cancel')}
          </Button>
          <Box alignItems="center" display="flex" justifyContent="space-between" flexDirection="row">
            <Typography variant="h1">{title}</Typography>
            {renderStepButton()}
          </Box>
          <Box
            sx={style.stepTitleContainer}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Typography variant="h2">{step.props.title}</Typography>
            <Typography variant="h2">
              {stepNumber + 1} / {totalSteps}
            </Typography>
          </Box>
          <Divider sx={style.divider} />
          <Box display={!state ? 'flex' : 'none'} flex={1} flexDirection="column">
            {step}
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default Wizard;
