import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Container, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { StatusCodes } from 'http-status-codes';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { account as accountApi } from '../api';
import { UnauthenticatedBox } from '../components';
import InputHookForm from '../components/FormControls/HookForm/InputHookForm';
import withReCaptcha from '../hoc/withReCaptcha';
import routes from '../routes';
import theme from '../style/theme';
import { RequestAccessInput } from '../types';
import { EMAIL_REGEX, PHONE_REGEX } from '../utils/constants';
import { getRequestAccessInitialValues } from '../utils/form';

const style = {
  title: {
    marginTop: 5,
    marginBottom: 2,
  },
  buttonContainer: {
    paddingTop: 4,
  },
  cancelLink: {
    alignSelf: 'flex-start',
  },
  error: {
    color: theme.palette.error.light,
  },
};

const RequestAccessChildren: React.FC = () => {
  const { t } = useTranslation();
  const [requestSent, setRequestSent] = useState(false);
  const [error, setError] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationRequest = Yup.object({
    username: Yup.string()
      .required(t('form:error.fieldRequired'))
      .matches(EMAIL_REGEX, { message: t('common:user.emailInvalid') }),
    phoneNumber: Yup.string()
      .required(t('form:error.fieldRequired'))
      .matches(PHONE_REGEX, { message: t('auth:requestAccess.phoneNumberInvalid') }),
    firstName: Yup.string().required(t('form:error.fieldRequired')),
    lastName: Yup.string().required(t('form:error.fieldRequired')),
    company: Yup.string().required(t('form:error.fieldRequired')),
  });

  const { handleSubmit, control, formState } = useForm<RequestAccessInput>({
    defaultValues: getRequestAccessInitialValues(),
    resolver: yupResolver(validationRequest),
  });

  const onRequestAccessPress = async (requestAccess: RequestAccessInput) => {
    try {
      if (executeRecaptcha) {
        const reCaptchaToken = await executeRecaptcha('requestAccess');
        await accountApi.requestAccess(requestAccess, reCaptchaToken);
        setRequestSent(true);
      } else {
        setError(t('auth:requestAccess.reCaptchaError'));
      }
    } catch (err: any) {
      if (err.response.status === StatusCodes.CONFLICT) {
        setError(t('auth:requestAccess.emailExists'));
      } else {
        setError(t('common:unknownError'));
      }
    }
  };

  return (
    <UnauthenticatedBox>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onRequestAccessPress)}>
          <Stack sx={style.title} spacing={2}>
            <Typography variant="h1">{t('auth:requestAccess.title')}</Typography>
            <Typography variant="paragraphSmall">
              {requestSent ? t('auth:requestAccess.confirmation') : t('auth:requestAccess.description')}
            </Typography>
          </Stack>
          {!requestSent ? (
            <>
              <InputHookForm
                variant="outlined"
                label={`${t('common:user.email')}*`}
                name="username"
                control={control}
              />
              <Stack direction="row" spacing={3}>
                <InputHookForm
                  variant="outlined"
                  label={`${t('auth:requestAccess.firstName')}*`}
                  name="firstName"
                  control={control}
                />
                <InputHookForm
                  variant="outlined"
                  label={`${t('auth:requestAccess.lastName')}*`}
                  name="lastName"
                  control={control}
                />
              </Stack>
              <InputHookForm
                variant="outlined"
                inputWidth="48%"
                label={`${t('auth:requestAccess.phoneNumber')}*`}
                name="phoneNumber"
                control={control}
              />
              <InputHookForm
                variant="outlined"
                label={`${t('auth:requestAccess.company')}*`}
                name="company"
                control={control}
              />
              <Stack sx={style.buttonContainer} spacing={3}>
                {error && <Typography variant="inputError">{error}</Typography>}
                <LoadingButton
                  loading={formState.isSubmitting}
                  type="submit"
                  disabled={formState.isSubmitting}
                  variant="contained"
                >
                  {t('auth:requestAccess.action')}
                </LoadingButton>
                <Button sx={style.cancelLink} href={routes.login.path} variant="outlined">
                  {t('common:cancel')}
                </Button>
              </Stack>
            </>
          ) : (
            <Button sx={style.cancelLink} href={routes.login.path} variant="outlined">
              {t('auth:login.back')}
            </Button>
          )}
        </form>
      </Container>
    </UnauthenticatedBox>
  );
};

export const RequestAccess = withReCaptcha(RequestAccessChildren);
