import MenuIcon from '@mui/icons-material/Menu';
import { Link, Stack, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import routes from '../../routes';
import theme, { sxHover } from '../../style/theme';
import { Logo } from '../Logo';
import { Notifications } from './Notifications';
import { PopupMenu } from './PopupMenu';
import { Profile } from './Profile';
import { SearchDocuments } from './SearchDocuments';

const style = {
  appBar: {
    color: theme.palette.grey[200],
    backgroundColor: theme.palette.common.white,
    boxShadow: '1px 2px',
    zIndex: theme.zIndex.modal + 1,
  },
  logoLarge: {
    mr: 2,
    display: { xs: 'none', md: 'flex' },
  },
  logoSmall: {
    display: { xs: 'flex', md: 'none' },
    height: '75%',
    maxHeight: 35,
    width: '100%',
  },
  logoSmallImg: {
    width: '100%',
    objectFit: 'contain',
  },
  toolbarSmall: {
    display: { xs: 'flex', md: 'none' },
  },
  toolbarLarge: {
    display: { xs: 'none', md: 'flex' },
    marginLeft: 5,
    alignItems: 'center',
    height: '100%',
  },
  menuSmall: {
    display: { xs: 'block', md: 'none' },
  },
  menuTitle: {
    paddingTop: '5px',
    color: theme.palette.grey[400],
    fontWeight: 500,
    ...sxHover,
  },
  currentMenuTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  baseMenu: {
    borderBottomWidth: 4,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    marginBottom: '-10px',
  },
  borderActive: {
    borderBottomColor: theme.palette.primary.main,
  },
};

type MenuOption = 'user' | 'notifications' | 'search' | 'nav';

type OpenMenu = {
  anchor: HTMLElement;
  name: MenuOption;
};

export function Navbar() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<OpenMenu | null>(null);

  const pages = [
    { title: t('quotations:list.title'), path: routes.quotations.path },
    { title: t('sales-orders:list.title'), path: routes.salesOrders.path },
  ];

  useEffect(() => setOpenMenu(null), [location.pathname]);

  const handleOpenMenu = useCallback(
    (target: HTMLElement | null, name: MenuOption) => {
      if (!target && openMenu?.name === name) {
        setOpenMenu(null);
      } else if (target && openMenu?.name !== name) {
        setOpenMenu({ anchor: target, name });
      }
    },
    [openMenu],
  );

  const handleCloseMenu = (name: MenuOption) => {
    if (openMenu?.name === name) {
      setOpenMenu(null);
    }
  };

  const handleOpenMenuSearch = useCallback(
    (target: HTMLElement | null) => handleOpenMenu(target, 'search'),
    [handleOpenMenu],
  );

  const handleOpenMenuUser = useCallback(
    (target: HTMLElement | null) => handleOpenMenu(target, 'user'),
    [handleOpenMenu],
  );

  const handleOpenMenuNotifications = useCallback(
    (target: HTMLElement | null) => handleOpenMenu(target, 'notifications'),
    [handleOpenMenu],
  );

  const getAnchor = (name: MenuOption) => (openMenu?.name === name ? openMenu.anchor : null);

  const isMenuActive = (path: string) =>
    location.pathname.includes(path) &&
    !location.pathname.includes(routes.createQuotation.path) &&
    !location.pathname.includes(routes.createSalesOrder.path);

  const renderSmallMenu = () => {
    return (
      <Box flexGrow={1} sx={style.toolbarSmall}>
        <IconButton size="large" onClick={(ev) => handleOpenMenu(ev.currentTarget, 'nav')}>
          <MenuIcon />
        </IconButton>
        <PopupMenu
          anchorEl={getAnchor('nav')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={!!getAnchor('nav')}
          onClose={() => handleCloseMenu('nav')}
          sx={style.menuSmall}
        >
          {pages.map((page) => (
            <MenuItem component={Link} href={page.path} key={page.title} onClick={() => handleCloseMenu('nav')}>
              <Typography
                color={isMenuActive(page.path) ? theme.palette.primary.main : theme.palette.common.black}
                fontWeight={isMenuActive(page.path) ? 700 : 400}
                variant="body1"
                textAlign="center"
              >
                {page.title}
              </Typography>
            </MenuItem>
          ))}
        </PopupMenu>
      </Box>
    );
  };

  const renderLargeMenu = () => {
    return (
      <Box flexGrow={1} sx={style.toolbarLarge}>
        {pages.map((page) => (
          <Box key={page.title} sx={[style.baseMenu, isMenuActive(page.path) && style.borderActive]} padding={2}>
            <Link
              href={page.path}
              onClick={() => handleCloseMenu('nav')}
              textTransform="none"
              sx={[style.menuTitle, isMenuActive(page.path) && style.currentMenuTitle]}
              variant="paragraphBig"
            >
              {page.title}
            </Link>
          </Box>
        ))}
      </Box>
    );
  };

  if (!user) {
    return null;
  }

  return (
    <AppBar sx={style.appBar} position="static">
      <Toolbar>
        <Box sx={style.logoLarge}>
          <Logo height={35} />
        </Box>
        {renderSmallMenu()}
        <Box flexGrow={1} sx={style.logoSmall}>
          <Logo style={style.logoSmallImg as any} />
        </Box>
        {renderLargeMenu()}
        <Stack direction="row" spacing={1.5}>
          <SearchDocuments anchorElement={getAnchor('search')} setAnchor={handleOpenMenuSearch} />
          <Notifications anchorElement={getAnchor('notifications')} setAnchor={handleOpenMenuNotifications} />
          <Profile anchorElement={getAnchor('user')} setAnchor={handleOpenMenuUser} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
