import { Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, isValid } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { LanguageCode } from '../../../types';
import { getCurrentLanguage } from '../../../utils/helper';
import Input from '../Input';
import InputErrorMessage from '../InputErrorMessage';
import theme from '../../../style/theme';

type Props = {
  defaultValue?: Date;
  inputProps: Omit<React.ComponentProps<typeof Input>, 'variant'>;
  name: string;
  control: Control<any, any>;
  hideError?: boolean;
  inputWidth?: number;
} & Omit<React.ComponentProps<typeof DatePicker>, 'value' | 'onChange' | 'renderInput'>;

const DatePickerHookForm = ({ defaultValue, name, control, hideError, inputProps, ...props }: Props) => {
  const [date, setDate] = useState(defaultValue ?? null);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, formState: { errors } }) => {
        return (
          <Stack id={name} width="100%">
            <Typography
              display="block"
              sx={{ marginBottom: '12px', paddingTop: 3 }}
              variant={inputProps.labelVariant ?? 'label'}
            >
              {inputProps.label}
            </Typography>
            <LocalizationProvider
              adapterLocale={getCurrentLanguage() === LanguageCode.fr ? fr : undefined}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                {...props}
                onChange={(date) => {
                  if (isValid(date)) {
                    onChange(format(date as Date, 'yyyy-MM-dd'));
                  }
                  setDate(date as Date);
                }}
                value={date}
                sx={{ backgroundColor: theme.palette.common.white, width: inputProps.inputWidth }}
              />
              <InputErrorMessage hideError={hideError} errors={errors} name={name} />
            </LocalizationProvider>
          </Stack>
        );
      }}
    />
  );
};

export default DatePickerHookForm;
