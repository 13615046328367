import { Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreationContext } from '../../../contexts/CreationContext';
import { CreationForm } from '../../../types';

import { useFormState } from 'react-hook-form';

const EmptyProducts = () => {
  const formState = useFormState<CreationForm>();
  const { t } = useTranslation();
  const context = useContext(CreationContext);
  return (
    <>
      <Typography>{t('form:step3.list.noProducts', { indexValueAt: context.type === 'Quotation' ? 0 : 1 })}</Typography>
      <Typography variant="inputError">{formState.errors.lineDetails?.message}</Typography>
    </>
  );
};
export default EmptyProducts;
