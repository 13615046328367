import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import InputHookForm from './InputHookForm';

const style = {
  quantityContainer: {
    '& .MuiInputBase-root': {
      height: '37px',
    },
    '& input': {
      paddingTop: '17px',
    },
    '& > div > div': {
      paddingTop: 0,
    },
  },
};

type Props = {
  name: string;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  max?: number;
  min?: number;
  disabled?: boolean;
};

const NumberHookForm = ({ name, control, setValue, max, min, disabled = false }: Props) => {
  const watchValue = useWatch({ name });

  const changeQuantity = (quantityDiff: number) => {
    const newQuantity = !watchValue ? 0 : watchValue;
    if (quantityDiff < 0 && newQuantity < 2) {
      return;
    }
    setValue(name, newQuantity + quantityDiff);
  };

  useEffect(() => {
    if (max !== undefined && watchValue > max) {
      setValue(name, max);
    }
    if (min !== undefined && watchValue < min) {
      setValue(name, min);
    }
  }, [watchValue, setValue, max, min, name]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={style.quantityContainer}>
      {!disabled && (
        <IconButton disabled={min != undefined && watchValue === min} onClick={() => changeQuantity(-1)}>
          <Remove />
        </IconButton>
      )}
      <InputHookForm disabled={disabled} inputWidth="60px" hideError type="number" name={name} control={control} />
      {!disabled && (
        <IconButton disabled={max != undefined && watchValue === max} onClick={() => changeQuantity(1)}>
          <Add />
        </IconButton>
      )}
    </Box>
  );
};

export default NumberHookForm;
