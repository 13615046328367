import { FormCreationBase } from '../components';
import { CreationContext } from '../contexts/CreationContext';

export function CreateQuotation() {
  return (
    <CreationContext.Provider value={{ type: 'Quotation', quotation: null }}>
      <FormCreationBase />
    </CreationContext.Provider>
  );
}
