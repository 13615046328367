import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logoFiretrol from '../assets/firetrol_logo_main.png';
import logoTornatech from '../assets/tornatech_logo_main.png';
import { Domain } from '../types';
import { getDomain } from '../utils/helper';

export function PoweredBy(props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const { t } = useTranslation();
  return (
    <Stack display="flex" flexDirection="column" justifyContent="center" alignItems="center" spacing={1}>
      <Typography fontSize={17} fontWeight={600} letterSpacing={0}>
        {t('common:poweredBy')}
      </Typography>
      <img {...props} src={getDomain() === Domain.firetrol ? logoFiretrol : logoTornatech}></img>
    </Stack>
  );
}
