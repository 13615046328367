import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { getCountries, getCountryStates } from '../../../services/countries.service';
import { Address, SelectOption } from '../../../types';
import { PHONE_REGEX } from '../../../utils/constants';
import { getAddressInitialValues } from '../../../utils/form';
import BaseModal from '../../BaseModal';
import CheckboxHookForm from '../../FormControls/HookForm/CheckboxHookForm';
import InputHookForm from '../../FormControls/HookForm/InputHookForm';
import SearchSelectHookForm from '../../FormControls/HookForm/SearchSelectHookForm';

const style = {
  saveCheckbox: {
    marginTop: '10px',
  },
  phoneNumberContainer: {
    maxWidth: '55%',
  },
};

type NewAddressForm = Address & { saveAddress: boolean };

type Props = {
  isVisible: boolean;
  onConfirm: (address: Address, saveAddress: boolean) => void;
  onCancel: () => void;
  currentAddress: NewAddressForm | null;
};

const NewAddressModal  = (props: Props) => {
  const { isVisible, onConfirm, onCancel, currentAddress } = props;
  const { t } = useTranslation();
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    (async () => setCountryOptions((await getCountries()).map((c) => ({ label: c.name, value: c.code }))))();
  }, []);

  const isStateMandatory = (country: string) => country == 'US' || country == 'CA';

  const validationAddress = Yup.object({
    consigneeName: Yup.string().required(t('form:error.fieldRequired')),
    addressLine1: Yup.string().required(t('form:error.fieldRequired')),
    country: Yup.string().required(t('form:error.fieldRequired')),
    city: Yup.string().required(t('form:error.fieldRequired')),
    contactPerson: Yup.string().required(t('form:error.fieldRequired')),
    contactPhoneNumber: Yup.string()
      .required(t('form:error.fieldRequired'))
      .matches(PHONE_REGEX, t('auth:requestAccess.phoneNumberInvalid')),
    zipCode: Yup.string().required(t('form:error.fieldRequired')),
    state: Yup.string().when(['country'], {
      is: isStateMandatory,
      then: (schema) => schema.required(t('form:error.fieldRequired')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const { reset, control, handleSubmit, setValue, trigger } = useForm({
    resolver: yupResolver(validationAddress),
    defaultValues: currentAddress ?? { ...getAddressInitialValues(), saveAddress: false },
  });
  const watchCountry = useWatch({ name: 'country', control });

  useEffect(() => {
    (async () =>
      setStateOptions((await getCountryStates(watchCountry)).map((s) => ({ label: s.name, value: s.code }))))();
    trigger('state');
  }, [watchCountry, trigger]);

  useEffect(() => {
    reset(currentAddress ?? { ...getAddressInitialValues(), saveAddress: false });
  }, [reset, currentAddress]);

  return (
    <BaseModal
      title={t('form:step2.newAddressTitle')}
      confirmText={t('form:step2.selectAddressConfirm')}
      isVisible={isVisible}
      onConfirm={handleSubmit((d: any) => onConfirm(d, d.saveAddress))}
      onCancel={() => {
        onCancel();
        reset();
      }}
      width={{ xs: '95%', sm: 600 }}
    >
      <form onSubmit={handleSubmit((d: any) => onConfirm(d, d.saveAddress))}>
        <Stack>
          <InputHookForm control={control} label={t('form:step2.address.consigneeName')} name="consigneeName" />
          <InputHookForm control={control} label={t('form:step2.address.addressLine1')} name="addressLine1" />
          <InputHookForm control={control} label={t('form:step2.address.addressLine2')} name="addressLine2" />
          <InputHookForm control={control} label={t('form:step2.address.addressLine3')} name="addressLine3" />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SearchSelectHookForm
                control={control}
                name="country"
                label={t('form:step2.address.country')}
                onChange={() => setValue('state', '')}
                options={countryOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputHookForm control={control} label={t('form:step2.address.city')} name="city" />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SearchSelectHookForm
                control={control}
                name="state"
                label={t('form:step2.address.state') + (isStateMandatory(watchCountry) ? ' *' : '')}
                options={stateOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputHookForm control={control} label={t('form:step2.address.zipCode')} name="zipCode" />
            </Grid>
          </Grid>
          <InputHookForm control={control} label={t('form:step2.address.contactPerson')} name="contactPerson" />
          <Box sx={style.phoneNumberContainer}>
            <InputHookForm
              control={control}
              label={t('form:step2.address.contactPhoneNumber')}
              name="contactPhoneNumber"
            />
          </Box>
          <Box sx={style.saveCheckbox}>
            <CheckboxHookForm control={control} label={t('form:step2.saveAddress')} name="saveAddress" />
          </Box>
        </Stack>
      </form>
    </BaseModal>
  );
};

export default NewAddressModal;
