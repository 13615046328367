import React, { useEffect } from 'react';
import { auth as authApi, settings as settingsApi } from '../api';
import { getLocalUser, removeLocalUser, setLocalUser } from '../services/auth.service';
import { Authentication, User } from '../types';
import Analytics from '../utils/analytics';
import { changeLanguage } from '../utils/helper';
import setAuthorizationHeader from '../utils/setAuthorizationHeader';

interface AuthContextType {
  isLoaded: boolean;
  user: User | null;
  login: (auth: Authentication) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

function useAuth() {
  const [user, setUser] = React.useState<User | null>(null);
  const [isLoaded, setLoaded] = React.useState<boolean>(getLocalUser() === null);

  useEffect(() => {
    const refresh = async (user: User) => {
      try {
        const { data } = await authApi.postWithRefreshToken(user.refreshToken);
        const updatedUser = parseUserData(data);
        setUser(updatedUser);
        setLocalUser(updatedUser);
        await setUserLanguage();
      } catch (ex: any) {
        logout();
      } finally {
        setLoaded(true);
      }
    };

    const localUser = getLocalUser();
    if (localUser) {
      setAuthorizationHeader(localUser.accessToken);
      refresh(localUser);
    }
  }, []);

  const logout = async () => {
    authApi.logout();
    setUser(null);
    removeLocalUser();
  };

  const setUserLanguage = async () => {
    const settings = await settingsApi.get();
    await changeLanguage(settings.data?.languageCode);
  };

  const parseUserData = (data: any) => ({
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    id: data.user.id,
    username: data.user.username,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
  });

  return {
    isLoaded,
    user,
    async login(auth: Authentication) {
      const { data } = await authApi.post(auth.username, auth.password);
      const userInfo = parseUserData(data);
      setLocalUser(userInfo);
      await setUserLanguage();
      setUser(userInfo);
      setLoaded(true);
      Analytics.sendEvent('click_login');
    },
    logout,
  };
}

export function AuthProvider({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer(): AuthContextType {
  return React.useContext(AuthContext) as AuthContextType;
}
