import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { salesOrders as salesOrdersApi } from '../../api';
import { CreationContext } from '../../contexts/CreationContext';
import useDebounce from '../../hooks/useDebounce';
import { CreationForm, CreationLineDetails, SalesOrderPriceDetails } from '../../types';
import { isCreationLineDetailsEquals } from '../../utils/form';
import { formatCurrency } from '../../utils/helper';

const style = {
  totalContainer: {
    minHeight: '100px',
  },
};

type QueryPrice = {
  isLoading: boolean;
  details: SalesOrderPriceDetails['priceDetails'] | null;
};

const isEqualLineDetails = (prev: CreationLineDetails[], next: CreationLineDetails[]) => {
  if (prev.length !== next.length) {
    return false;
  }

  for (let i = 0; i < prev.length; i++) {
    const prevLine = prev[i];
    const nextLine = next.find((n, nextIndex) => n.productId === prevLine.productId && nextIndex === i);
    if (!nextLine || !isCreationLineDetailsEquals(prevLine, nextLine)) {
      return false;
    }
  }

  return true;
};

const PriceTotal = () => {
  const { t } = useTranslation();
  const { quotation } = useContext(CreationContext);
  const { getValues } = useFormContext<CreationForm>();
  const [queryPrice, setQueryPrice] = useState<QueryPrice>({ isLoading: false, details: null });
  const watchLineDetails = useWatch({ name: 'lineDetails' });
  const debouncedLineDetails = useDebounce(watchLineDetails, 2000, isEqualLineDetails);
  const priceDetails = quotation ? quotation.details : queryPrice.details;

  const onPriceRowChange = useCallback(async () => {
    const customerAccountId = getValues('customerAccountId');
    const territoryId = getValues('territoryId');
    const lineDetails = getValues('lineDetails');

    if (customerAccountId && territoryId && !quotation) {
      try {
        setQueryPrice({ isLoading: true, details: null });
        const result = await salesOrdersApi.calculateTotal(customerAccountId, territoryId, lineDetails);
        setQueryPrice({ isLoading: false, details: result.data.priceDetails });
      } catch {
        setQueryPrice((prev) => ({ ...prev, isLoading: false }));
      }
    }
  }, [getValues, quotation]);

  useEffect(() => {
    onPriceRowChange();
  }, [debouncedLineDetails, onPriceRowChange]);

  return (
    <Stack sx={style.totalContainer} direction="row" justifyContent="flex-end" spacing={3}>
      {queryPrice.isLoading || !priceDetails ? (
        <Box>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Stack spacing={1.5} alignItems="flex-end">
            <Typography variant="paragraphBig">{t('form:step3.price.total')}</Typography>
            <Typography variant="paragraphBig">{t('form:step3.price.taxes')}</Typography>
            <Typography variant="h2">{t('form:step3.price.grandTotal')}</Typography>
          </Stack>
          <Stack spacing={1.5} alignItems="flex-end">
            <Typography variant="paragraphBig">{formatCurrency(priceDetails.total - priceDetails.taxes)}</Typography>
            <Typography variant="paragraphBig">{formatCurrency(priceDetails.taxes)}</Typography>
            <Typography variant="h2">{formatCurrency(priceDetails.total)}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};
export default PriceTotal;
