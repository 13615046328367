import { useCallback, useEffect, useState } from 'react';

export default function useDebounce(value: any, delay: number, isEqual?: (prev: any, next: any) => boolean): any {
  const getFormattedValue = useCallback(() => {
    return isEqual && typeof value == 'object' ? JSON.parse(JSON.stringify(value)) : value;
  }, [value, isEqual]);

  const [debouncedValue, setDebouncedValue] = useState(getFormattedValue());

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue((prev: any) => {
        if (!isEqual || !isEqual(prev, value)) {
          // deep copy if equal function and object to avoid same reference value
          return getFormattedValue();
        }
        return prev;
      });
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, isEqual, getFormattedValue]);

  return debouncedValue;
}
