import axios, { AxiosResponse } from 'axios';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import JsFileDownloader from 'js-file-downloader';
import { env } from '../env';
import { getLocalUser, setTokens } from '../services/auth.service';
import {
  Countries,
  CreationForm,
  CreationLineDetails,
  CreationQuotationResponse,
  CreationSalesOrderResponse,
  DocumentType,
  Domain,
  LineOption,
  Notification,
  PagingResult,
  ProductFamilies,
  ProductFamilyDetails,
  ProductOptions,
  ProductPrice,
  Quotation,
  RequestAccessInput,
  SalesOrder,
  SalesOrderPriceDetails,
  SearchDocument,
  SearchProducts,
  SearchProductsParams,
  SearchQuotation,
  SearchSalesOrder,
  Settings,
  ShippingInstructions,
  Territories,
  UserPlants,
} from '../types';
import { combineUrl, getDomain } from '../utils/helper';
import { PaymentIntent } from '@stripe/stripe-js';

// Headers
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.baseURL = env.REACT_APP_API_URL;
axios.defaults.headers.common['App-Token'] =
  getDomain() === Domain.firetrol
    ? env.REACT_APP_CLIENT_PORTAL_API_JWT_FIRETROL
    : env.REACT_APP_CLIENT_PORTAL_API_JWT_TORNATECH;

export const auth = {
  post: (email: string, password: string): Promise<any> => {
    return axios.post(
      'auth/login',
      {
        username: email,
        password,
      },
      { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig,
    );
  },
  postWithRefreshToken: (refreshToken: string | null): Promise<any> => {
    return axios.post('auth/token', { refresh_token: refreshToken }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
  logout: (): Promise<AxiosResponse<any>> => {
    return axios.post('auth/logout', null, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
};

export const account = {
  requestAccess: (
    requestAccessInput: RequestAccessInput,
    reCaptchaToken: string,
  ): Promise<AxiosResponse<any> | any> => {
    return axios.post(
      'user',
      {
        username: requestAccessInput.username,
        firstName: requestAccessInput.firstName,
        lastName: requestAccessInput.lastName,
        company: requestAccessInput.company,
        phoneNo: requestAccessInput.phoneNumber,
        reCaptchaToken,
      },
      { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig,
    );
  },
  forgotPassword: (username: string): Promise<AxiosResponse<any> | any> => {
    return axios.post('user/password', { username }, {
      skipAuthRefresh: true,
    } as AxiosAuthRefreshRequestConfig);
  },
};

export const notification = {
  count: (): Promise<AxiosResponse<any> | any> => {
    return axios.get('notifications/unread-count');
  },
  get: (limit: number, offset: number): Promise<AxiosResponse<PagingResult<Notification>>> => {
    return axios.get('notifications', { params: { limit, offset } });
  },
  readAll: (): Promise<AxiosResponse<any> | any> => {
    return axios.patch('notifications/read');
  },
  clearAll: (): Promise<AxiosResponse<any> | any> => {
    return axios.delete('notifications');
  },
  delete: (id: string): Promise<AxiosResponse<any> | any> => {
    return axios.delete(`notifications/${id}`);
  },
};

export const settings = {
  get: (): Promise<AxiosResponse<Settings> | any> => {
    return axios.get('user/settings');
  },
  update: (settings: Settings): Promise<AxiosResponse<any> | any> => {
    return axios.put('user/settings', { ...settings });
  },
};

export const documents = {
  search: (serialNumber: string): Promise<AxiosResponse<SearchDocument>> => {
    return axios.get('documents', { params: { keyword: serialNumber, type: DocumentType.WorkOrder } });
  },
  download: (ids: string[] | string, type: DocumentType) => {
    const formattedIds = Array.isArray(ids) ? ids.join(',') : ids;
    return new JsFileDownloader({
      url: combineUrl(env.REACT_APP_API_URL, `documents/${formattedIds}?type=${type}`),
      headers: [
        { name: 'Authorization', value: axios.defaults.headers.common['Authorization'] as string },
        { name: 'App-Token', value: axios.defaults.headers.common['App-Token'] as string },
      ],
    });
  },
  upload: (files: File[], entityId: string, type: DocumentType): Promise<AxiosResponse<any>> => {
    const formData = new FormData();
    files.forEach((f) => {
      formData.append('attachments', f, f.name);
    });
    return axios.post(`documents?type=${type}&entityId=${entityId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

export const quotations = {
  search: (
    limit: number,
    offset: number,
    keywords: string,
    status: string,
  ): Promise<AxiosResponse<PagingResult<SearchQuotation>>> => {
    return axios.get('quotations', { params: { limit, offset, keywords, status: status || undefined } });
  },
  get: (id: string): Promise<AxiosResponse<Quotation>> => {
    return axios.get(`quotations/${id}`);
  },
  create: (form: CreationForm): Promise<AxiosResponse<CreationQuotationResponse>> => {
    return axios.post('quotations', { ...form });
  },
};

export const salesOrders = {
  search: (
    limit: number,
    offset: number,
    keywords: string,
    status: string,
  ): Promise<AxiosResponse<PagingResult<SearchSalesOrder>>> => {
    return axios.get('sales-orders', { params: { limit, offset, keywords, status: status || undefined } });
  },
  get: (id: string): Promise<AxiosResponse<SalesOrder>> => {
    return axios.get(`sales-orders/${id}`);
  },
  calculateTotal: async (
    customerAccountId: string,
    territoryId: string,
    lineDetails: CreationLineDetails[],
  ): Promise<AxiosResponse<SalesOrderPriceDetails>> => {
    return axios.post(`sales-orders/total`, {
      customerAccountId,
      territoryId,
      lineDetails: lineDetails.map((line) => ({
        productId: line.productId,
        quantity: line.quantity,
        options: line.options?.map((o) => ({ id: o.id, quantity: o.quantity })) ?? [],
      })),
    });
  },
  create: (form: CreationForm): Promise<AxiosResponse<CreationSalesOrderResponse>> => {
    return axios.post('sales-orders', { ...form });
  },
};

export const user = {
  getPlants: (): Promise<AxiosResponse<UserPlants>> => {
    return axios.get('user/plants');
  },
};

export const countries = {
  getCountries: (): Promise<AxiosResponse<Countries>> => {
    return axios.get('countries');
  },
};

export const shipping = {
  getShippingInstructions: (plantId: string): Promise<AxiosResponse<ShippingInstructions>> => {
    return axios.get('shipping/instructions', {
      params: { brand: getDomain(), plantId },
    });
  },
};

export const territories = {
  getTerritories: (plantId: string): Promise<AxiosResponse<Territories>> => {
    return axios.get('territories', {
      params: { brand: getDomain(), plantId },
    });
  },
};

export const products = {
  searchProducts: (params: SearchProductsParams): Promise<AxiosResponse<SearchProducts>> => {
    const cleanParams: any = { ...params };
    for (const key of Object.keys(params)) {
      if (cleanParams[key] === '') {
        delete cleanParams[key];
      }
    }
    return axios.get('products', {
      params: { ...cleanParams, brand: getDomain() },
    });
  },
  getProductFamilies: (plantId: string): Promise<AxiosResponse<ProductFamilies>> => {
    return axios.get('products/families', {
      params: { plantId, brand: getDomain() },
    });
  },
  getProductFamilyDetails: (familyId: string): Promise<AxiosResponse<ProductFamilyDetails>> => {
    return axios.get(`products/families/${familyId}`);
  },
  getProductOptions: (productId: string, customerAccountId: string | null): Promise<AxiosResponse<ProductOptions>> => {
    return axios.get(`products/${productId}/options`, { params: { customerAccountId } });
  },
  getPrice: (
    customerAccountId: string,
    territoryId: string,
    line: Partial<CreationLineDetails>,
  ): Promise<AxiosResponse<ProductPrice>> => {
    return axios.post(`products/${line.productId}/price`, {
      customerAccountId,
      territoryId,
      quantity: line.quantity,
      options: line.options?.map((o) => ({ id: o.id, quantity: o.quantity })) ?? [],
    });
  },
};

export const payment = {
  createIntent: (accountId: string, orderId: string): Promise<AxiosResponse<PaymentIntent>> => {
    return axios.post('payment/create-payment-intent', { orderId, accountId });
  },
}

export const options = {
  searchOptions: (
    limit: number,
    offset: number,
    keyword: string,
    customerAccountId: string | null,
    productId?: string,
  ): Promise<AxiosResponse<PagingResult<LineOption>>> => {
    return axios.get('options', { params: { limit, offset, keyword, customerAccountId, productId } });
  },
};
createAuthRefreshInterceptor(axios, (failedRequest) => {
  const currentUser = getLocalUser();
  if (!currentUser) {
    return Promise.reject();
  }
  return auth.postWithRefreshToken(currentUser.refreshToken).then(({ data }) => {
    failedRequest.response.config.headers['Authorization'] = `Bearer ${data.accessToken}`;
    setTokens(data.accessToken, data.refreshToken);
    return Promise.resolve();
  });
});
