import { SvgIconComponent } from '@mui/icons-material';
import { Badge, BadgeProps, Button } from '@mui/material';
import * as React from 'react';
import theme, { sxHover } from '../../style/theme';

const style = {
  icon: {
    color: theme.palette.grey[400],
    minWidth: 50,
    height: 50,
    borderRadius: 2,
    padding: 0,
    ...sxHover,
  },
  iconActive: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
  },
};

type Props = {
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  icon: SvgIconComponent;
  badgeProps?: BadgeProps;
};

export function IconMenu(props: Props) {
  const { isActive, onClick, icon, badgeProps } = props;

  const renderIconElement = () => {
    return React.createElement(icon, { fontSize: 'large' });
  };

  return (
    <Button sx={[style.icon, isActive && style.iconActive]} onClick={onClick}>
      {badgeProps ? React.createElement(Badge, badgeProps as any, renderIconElement()) : renderIconElement()}
    </Button>
  );
}
