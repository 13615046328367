// types

export type User = {
  id: string;
  accessToken: string;
  refreshToken: string;
  username: string;
  firstName: string;
  lastName: string;
};

export type Authentication = {
  username: string;
  password: string;
};

export type RequestAccessInput = {
  username: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  company: string;
};

export type ShippingDateChange = {
  lineNumbers: number[];
  productCodes: string[];
  shippingDate: string;
};

export type Notification = {
  id: string;
  type: NotificationType;
  orderId: string;
  isRead: boolean;
  date: Date;
  data: {
    soNumber: string;
  } & (
    | {
      newStatus: OrderStatus;
    }
    | {
      shipmentNumber: string;
    }
    | ShippingDateChange
  );
};

export type Settings = {
  languageCode: LanguageCode;
  notifications: NotificationType[];
  notifyEmail: boolean;
  notifyPortal: boolean;
};

export type ExpandIconMenuProps = {
  setAnchor: (anchor: null | HTMLElement) => void;
  anchorElement: HTMLElement | null;
};

export type SearchDocument = {
  workOrderDetails: {
    woId: string;
    woStatus: WorkOrderStatus;
    shipmentNumber: string;
    salesOrderId: string;
  } | null;
  documents: Document[];
};

export type Document = {
  documentId: string;
  name: string;
  type: string;
  size: number;
};

export type SearchQuotation = {
  id: string;
  companyName: string;
  quotationNumber: string;
  rfq: string;
  status: QuotationStatus;
  alert: string;
  total: number;
  currency: string;
  leadTime: number;
  expiredDate: string;
  revision: number;
};

export type Quotation = {
  details: QuotationDetails;
  lineDetails: QuotationLine[];
  shipping: Address;
};

export type QuotationDetails = {
  references: string;
  reference1: string;
  reference2: string;
  reference3: string;
  customerAccountId: string;
  territoryId: string;
} & SearchQuotation &
  TotalPriceDetails;

export type QuotationLine = {
  id: string;
  lineNumber: number;
  productCode: string;
  description: string;
  options: string[] | LineOption[];
  customerReference: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  documentIds: string[];
  productId: string;
  lineNote: string;
  fla: number | null;
  lrc: number | null;
} & WorkOrder;

export type LineOption = {
  id: string;
  quantity: number;
  code: string;
  description: {
    [key in LanguageCode]: string;
  };
  maxQuantity: number;
  allowMultiple: boolean;
  category: string;
};

export type Address = {
  addressId?: string;
  consigneeName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  country: string;
  state: string;
  zipCode: string;
  city: string;
  contactPerson: string;
  contactPhoneNumber: string;
};

export type SearchSalesOrder = {
  id: string;
  companyName: string;
  soNumber: string;
  poNumber: string;
  status: OrderStatus;
  alert: string;
  currency: string;
  isAcknowledged: boolean;
  hasShipments: boolean;
  total: number;
  customerAccountId: string;
};

export type SalesOrder = {
  details: SalesOrderDetails;
  lineDetails: SalesOrderLine[];
  shipments: SalesOrderShipment[];
};

export type SalesOrderDetails = {
  references: string;
  shipToAddress: string;
  coverage: string;
  ccDisabledMessage?: {
    en: string
    fr: string
  },
  ccAuthorized: boolean
  paymentConfirmationId?: string
} & SearchSalesOrder &
  TotalPriceDetails;

export type SalesOrderLine = {
  lineNumber: number;
  productCode: string;
  description: string;
  options: string[] | LineOption[];
  customerReference: string;
  orderQty: number;
  balanceQty: number;
  shippingDate: string;
  unitPrice: number;
  totalPrice: number;
  serialNumbers: WorkOrder[];
};

export type SalesOrderShipment = {
  id: string;
  shipment: string;
  address: string;
  shippedDate: string;
  trackingUrl: string;
  trackingNumber: string;
  creditNoteIds: string[];
  salesInvoiceId: string;
  carrier: {
    [key in LanguageCode]: string;
  };
};

export type WorkOrder = {
  serialNumber: string;
  woId: string;
  woStatus: WorkOrderStatus;
  shipment?: string;
  hasAttachments: boolean;
};

export type PagingResult<T> = {
  meta: {
    limit: number;
    offset: number;
    totalCount: number;
  };
  data: T[];
};

export type BaseDashboardSearchQuery = {
  keywords: string;
  offset: number;
  status: string;
  resetPagination: boolean;
};

export type CreationQuotationResponse = {
  quotationId: string;
  quotationNumber: string;
};

export type CreationSalesOrderResponse = {
  soId: string;
  soNumber: string;
};

export type CreationResponseState = {
  state: 'success' | 'error' | 'loading';
  code: string;
  message?: string;
};

export type CreationForm = {
  plant: string;
  customerAccountId: string | null;
  territoryId: string;
  rfq?: string;
  poNumber?: string;
  reference1: string;
  reference2: string;
  reference3: string;
  wishLeadTime: number | null;
  requiredArrivalDate: string;
  attachments?: File[];
  shipping: Shipping;
  lineDetails: CreationLineDetails[];
  quotationId?: string;
  termsAccepted?: boolean;
};

export type Shipping = {
  instructionIds: string[];
  shippingNote: string;
  saveAddress: boolean;
} & Address;

export type UserPlant = {
  id: string;
  name: string;
  customerAccounts: CustomerAccount[];
}

export type UserPlants = {
  plants: UserPlant[];
};

export type CustomerAccount = {
  id: string;
  name: string;
  currency: string;
  ccAuthorized: boolean
  stripeAccountId: string
  addresses: (Address & { isDefault: boolean })[];
};

export type Countries = {
  countries: {
    code: string;
    name: string;
    states: {
      code: string;
      name: string;
    }[];
  }[];
};

export type ShippingInstructions = {
  shippingInstructions: {
    id: string;
    label: {
      [key in LanguageCode]: string;
    };
  }[];
};

export type BaseProduct = {
  id: string;
  code: string;
  description: {
    [key in LanguageCode]: string;
  };
};

export type SearchProductsParams = {
  plantId: string;
  code?: string;
  familyId?: string;
  batteryTypeId?: string;
  batteryVoltageId?: string;
  dcVoltageId?: string;
  frequencyId?: string;
  horsepowerRatingId?: string;
  lineVoltageId?: string;
  phaseVoltageId?: string;
  power?: number;
  powerUnitId?: string;
  shortCircuitRatingId?: string;
  startStopOptionId?: string;
  customerAccountId: string;
};

export type SearchProducts = {
  products: BaseProduct[];
};

export type ProductFamilies = {
  productFamilies: {
    id: string;
    code: string;
    name: {
      [key in LanguageCode]: string;
    };
  }[];
};

export type ProductFamilyDetails = {
  lineVoltages: EnumValue[];
  dcVoltages: EnumValue[];
  powers: EnumValue[];
  powerUnits: EnumValue[];
  frequencies: EnumValue[];
  startStopOptions: EnumValue[];
  shortCircuitRatings: EnumValue[];
  horsepowerRatings: EnumValue[];
  phaseVoltages: EnumValue[];
  batteryTypes: EnumValue[];
  batteryVoltages: EnumValue[];
};

export type EnumValue = {
  id: string;
  value: string;
};

export type CreationLineDetails = {
  productId: string;
  productCode: string;
  quantity: number;
  options: LineOption[];
  lineNote: string;
  fla: number | null;
  lrc: number | null;
  reference: string;
  requiredArrivalDate: string;
  quotationLineId?: string;
  productDescription?: {
    [key in LanguageCode]: string;
  };
};

export type Territories = {
  territories: {
    id: string;
    code: string;
    description: string;
  }[];
};

export type ProductPrice = {
  productPriceDetails: {
    productId: string;
    unitPrice: number;
    netBase: number;
    netOption: number;
    totalPrice: number;
  };
};

export type TotalPriceDetails = {
  total: number;
  taxes: number;
};

export type ProductOptions = {
  options: LineOption[];
};

export type SalesOrderPriceDetails = {
  priceDetails: TotalPriceDetails;
};

export type SelectOption = {
  label: string;
  value: string;
};

// enums

export enum DocumentType {
  Document = 'Document',
  WorkOrder = 'WorkOrder',
  Quotation = 'Quotation',
  SalesOrder = 'SalesOrder',
  SalesInvoice = 'SalesInvoice',
  CreditNote = 'CreditNote',
}

export enum NotificationType {
  NewShipment = 'NewShipment',
  StatusChange = 'StatusChange',
  ShippingDateChange = 'ShippingDateChange',
  QuoteStatusChange = 'QuoteStatusChange',
}

export enum OrderStatus {
  Open = 'Open',
  Shipped = 'Shipped',
  Canceled = 'Canceled',
  Draft = 'Draft',
}

export enum QuotationStatus {
  Draft = 'Draft',
  Active = 'Active',
  Expired = 'Expired',
}

export enum LanguageCode {
  fr = 'fr',
  en = 'en',
}

export enum Domain {
  firetrol = 'firetrol',
  tornatech = 'tornatech',
}

export enum WorkOrderStatus {
  Open = 'Open',
  ReadyToShip = 'ReadyToShip',
  Shipped = 'Shipped',
}
