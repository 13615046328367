import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Logo, PoweredBy, UnauthenticatedBox } from '../components';
import InputHookForm from '../components/FormControls/HookForm/InputHookForm';
import useAuth from '../hooks/useAuth';
import routes from '../routes';
import theme from '../style/theme';
import { Authentication } from '../types';

const style = {
  title: {
    marginTop: 10,
    marginBottom: 1,
  },
  forgotLink: {
    alignContent: 'flex-end',
    marginTop: 3,
    color: theme.palette.primary.main,
  },
  requestAccessLink: {
    alignSelf: 'flex-start',
  },
  poweredBy: {
    marginTop: '60px',
  },
};

export function Login() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const [error, setError] = useState('');

  const validationLogin = Yup.object({
    username: Yup.string().required(t('form:error.fieldRequired')),
    password: Yup.string().required(t('form:error.fieldRequired')),
  });

  const { handleSubmit, control, formState } = useForm<Authentication>({
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(validationLogin),
  });

  const onLoginPress = async (auth: Authentication) => {
    try {
      await login(auth);
    } catch (ex) {
      setError(t('auth:login.invalidLogin'));
    }
  };

  return (
    <UnauthenticatedBox>
      <Logo height={110} />
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit(onLoginPress)}>
          <Typography sx={style.title} variant="h1">
            {t('auth:login.title')}
          </Typography>
          <InputHookForm
            variant="outlined"
            label={t('common:user.email')}
            type="email"
            name="username"
            control={control}
          />
          <InputHookForm
            variant="outlined"
            label={t('auth:login.password')}
            name="password"
            type="password"
            control={control}
          />
          {error && <Typography variant="inputError">{error}</Typography>}
          <Stack spacing={3}>
            <Box display="flex" justifyContent="flex-end">
              <Link variant="buttonMedium" sx={style.forgotLink} href={routes.forgotPassword.path}>
                {t('auth:login.forgotPassword')}
              </Link>
            </Box>
            <LoadingButton loading={formState.isSubmitting} type="submit" variant="contained">
              {t('auth:login.action')}
            </LoadingButton>
            <Button sx={style.requestAccessLink} href={routes.requestAccess.path} variant="outlined">
              {t('auth:requestAccess.title')}
            </Button>
          </Stack>
        </form>
        <Box sx={style.poweredBy}>
          <PoweredBy width={200} />
        </Box>
      </Container>
    </UnauthenticatedBox>
  );
}
