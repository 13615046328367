import { Delete, Error, LocalShipping, Notifications as NotificationIcon, Settings, Today } from '@mui/icons-material';
import {
  Badge,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatDistance } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { notification as notificationApi } from '../../api';
import routes from '../../routes';
import theme, { sxHover } from '../../style/theme';
import { ExpandIconMenuProps, Notification, NotificationType } from '../../types';
import { FETCH_COUNT_NOTIFICATION_INTERVAL, LIMIT_NOTIFICATIONS } from '../../utils/constants';
import { distinctByProp, getDateLocale } from '../../utils/helper';
import IconTextPlaceholder from '../IconTextPlaceholder';
import { IconMenu } from './IconMenu';
import { PopupMenu } from './PopupMenu';

const style = {
  badge: {
    border: '2px solid ' + theme.palette.common.white,
    fontWeight: '700',
    fontSize: 14,
    height: 25,
    borderRadius: 15,
    paddingRight: 5,
  },
  drawerContainer: {
    paddingTop: '10px',
    width: {
      xs: '100%',
      sm: '500px',
    },
  },
  list: {
    marginTop: 1,
    height: '450px',
    overflow: 'auto',
  },
  listItem: {
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 1,
  },
  listItemUnread: {
    backgroundColor: theme.palette.grey[100],
  },
  typeIcon: {
    marginTop: 1.5,
    fontSize: 25,
  },
};

export function Notifications({ anchorElement, setAnchor }: ExpandIconMenuProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [notificationCount, setNotificationCount] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const page = useRef(-1);
  const isReadMount = useRef(false);

  useEffect(() => {
    const fetchCount = () => {
      notificationApi.count().then(({ data }) => setNotificationCount(data.count));
    };

    const fetchCountInterval = setInterval(fetchCount, FETCH_COUNT_NOTIFICATION_INTERVAL);

    fetchCount();

    return () => {
      clearInterval(fetchCountInterval);
    };
  }, []);

  useEffect(() => {
    if (anchorElement) {
      if (page.current == -1) {
        getNotifications();
      } else if (notificationCount > 0) {
        getNotifications(notificationCount);
      }
    }
  }, [anchorElement, notificationCount]);

  useEffect(() => {
    const onUnload = () => anchorElement && readAllNotifications();

    const readAllNotifications = async () => {
      await notificationApi.readAll();
      setNotificationCount(0);
      setNotifications((prev) => prev.map((n) => ({ ...n, isRead: true })));
    };

    if (isReadMount.current && !anchorElement) {
      readAllNotifications();
    }

    isReadMount.current = true;
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [anchorElement]);

  const getNotifications = async (fetchNewCount?: number) => {
    page.current = Math.max(0, page.current + 1);
    const { data } = await notificationApi.get(fetchNewCount ?? LIMIT_NOTIFICATIONS, fetchNewCount ? 0 : page.current);
    setNotifications((prev) => {
      let updatedList = [...prev, ...data.data];
      if (fetchNewCount) {
        updatedList = [...data.data, ...prev];
        page.current = Math.floor((updatedList.length - 1) / LIMIT_NOTIFICATIONS) - 1;
      }
      return distinctByProp(updatedList, 'id');
    });
    if (!fetchNewCount) {
      setHasMoreItems(data.data.length === LIMIT_NOTIFICATIONS);
    }
  };

  const onDeletePress = async (id: string, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    await notificationApi.delete(id);
    const index = notifications.findIndex((n) => n.id === id);
    if (!notifications[index].isRead) {
      setNotificationCount((prev) => prev - 1);
    }
    page.current = Math.floor((notifications.length - 1) / LIMIT_NOTIFICATIONS) - 1;
    setNotifications((prev) => prev.slice(0, index).concat(prev.slice(index + 1)));
  };

  const onClearAllPress = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    await notificationApi.clearAll();
    setNotifications([]);
    setNotificationCount(0);
  };

  const handleOpenNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(isActive() ? null : event.currentTarget);
  };

  const handleCloseNotification = () => setAnchor(null);

  const isActive = () => !!anchorElement;

  const renderIcon = () => (
    <IconMenu
      badgeProps={{
        badgeContent: notificationCount,
        color: 'error',
        max: 99,
        overlap: 'circular',
        componentsProps: {
          badge: { style: style.badge },
        },
      }}
      isActive={isActive()}
      onClick={handleOpenNotification}
      icon={NotificationIcon}
    />
  );

  const renderHeader = () => (
    <Container>
      <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="h2">{t('notifications:title')}</Typography>
        <IconButton size="small" href={routes.settings.path}>
          <Settings sx={sxHover} htmlColor={palette.grey[400]} />
        </IconButton>
      </Box>
    </Container>
  );

  const renderEmptyNotification = () => (
    <IconTextPlaceholder containerSx={style.list} title={t('notifications:none')} icon={NotificationIcon} />
  );

  const renderNotification = ({ isRead, id, type, date, data, orderId }: Notification) => (
    <ListItem
      onClick={() => {
        const routePath = type === NotificationType.QuoteStatusChange ? routes.quotation.path : routes.salesOrder.path;
        const newRoute = routePath.replace(':id', orderId);
        navigate(newRoute, { replace: true });
      }}
      key={id}
      sx={[style.listItem, !isRead && style.listItemUnread]}
      divider
    >
      <Stack direction="row" spacing={2}>
        <Badge overlap="circular" color="error" invisible={isRead} variant="dot">
          {renderNotificationType(isRead, type)}
        </Badge>
        <Stack spacing={0.3}>
          <Typography variant="menuItem">{t(`notifications:type.${type}.title`)}</Typography>
          <Typography variant="caption">{t(`notifications:type.${type}.description`, { data })}</Typography>
          <Typography color={palette.grey[400]} variant="caption">{`${formatDistance(new Date(date), new Date(), {
            addSuffix: true,
            locale: getDateLocale(),
          })}`}</Typography>
        </Stack>
      </Stack>
      <IconButton onClick={(ev) => onDeletePress(id, ev)}>
        <Delete sx={sxHover} htmlColor={palette.grey[400]} />
      </IconButton>
    </ListItem>
  );

  const renderNotificationType = (isRead: boolean, type: NotificationType) => {
    const props = { sx: style.typeIcon, htmlColor: isRead ? palette.grey[300] : palette.primary.main };
    switch (type) {
      case 'NewShipment':
        return <LocalShipping {...props} />;
      case 'ShippingDateChange':
        return <Today {...props} />;
      default:
        return <Error {...props} />;
    }
  };

  const renderLoading = () => (
    <Box display="flex" justifyContent="center" key={0}>
      <CircularProgress />
    </Box>
  );

  return (
    <Box flexGrow={0}>
      {renderIcon()}
      <PopupMenu
        anchorEl={anchorElement}
        open={isActive()}
        onClose={handleCloseNotification}
        onClick={handleCloseNotification}
      >
        <Box sx={style.drawerContainer}>
          {renderHeader()}
          {!hasMoreItems && notifications.length === 0 ? (
            renderEmptyNotification()
          ) : (
            <>
              <Container sx={style.list}>
                <InfiniteScroll
                  pageStart={-1}
                  initialLoad={false}
                  loadMore={() => getNotifications()}
                  hasMore={hasMoreItems}
                  useWindow={false}
                  loader={renderLoading()}
                >
                  {notifications.map(renderNotification)}
                </InfiniteScroll>
              </Container>
              <Divider />
              <MenuItem onClick={(ev) => onClearAllPress(ev)}>
                <ListItemIcon>
                  <Delete htmlColor={palette.grey[400]} fontSize="small" />
                </ListItemIcon>
                <Typography color={palette.grey[400]} textTransform="uppercase" variant="buttonSmall">
                  {t('notifications:clearAll')}
                </Typography>
              </MenuItem>
            </>
          )}
        </Box>
      </PopupMenu>
    </Box>
  );
}
