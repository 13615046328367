import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { env } from '../env';
import { Domain } from '../types';
import { getDomain } from '../utils/helper';

const withReCaptcha =
  (Component: React.ComponentType): React.FC =>
  ({ ...props }) => {
    const getRecaptchaKey = () =>
      getDomain() === Domain.firetrol ? env.REACT_APP_RECAPTCHA_FIRETROL_KEY : env.REACT_APP_RECAPTCHA_TORNATECH_KEY;

    return (
      <GoogleReCaptchaProvider reCaptchaKey={getRecaptchaKey()}>
        <Component {...props} />
      </GoogleReCaptchaProvider>
    );
  };

export default withReCaptcha;
