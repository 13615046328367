import { Stack, SxProps, Theme } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import InputErrorMessage from '../InputErrorMessage';
import SearchSelect from '../SearchSelect';

type Props = {
  onChange?: (value: string) => void;
  name: string;
  control: Control<any, any>;
  hideError?: boolean;
  sx?: SxProps<Theme>
} & Omit<React.ComponentProps<typeof SearchSelect>, 'selectedValue'>;

const SearchSelectHookForm = ({ name, control, onChange: onChangeParent, hideError, sx, ...props }: Props) => (
  <Controller
    render={({ field: { onChange, value }, formState: { errors } }) => (
      <Stack id={name} sx={sx}>
        <SearchSelect
          {...props}
          selectedValue={value}
          onChange={(val) => {
            onChange(val);
            onChangeParent && onChangeParent(val);
          }}
        />
        <InputErrorMessage name={name} hideError={hideError} errors={errors} />
      </Stack>
    )}
    control={control}
    name={name}
  />
);

export default SearchSelectHookForm;
