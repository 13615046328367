import React from 'react';
import * as Yup from 'yup';

export type WizardStepProps = {
  validationSchema: Yup.AnyObjectSchema;
  title: string;
  children?: JSX.Element | JSX.Element[];
};

const WizardStep = ({ children }: WizardStepProps) => <>{children}</>;
export default WizardStep;
