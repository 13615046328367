import Cookies from 'js-cookie';
import { User } from '../types';
import setAuthorizationHeader from '../utils/setAuthorizationHeader';

export function removeLocalUser() {
  localStorage.removeItem('user');
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
  setAuthorizationHeader(null);
}

export function setLocalUser(user: User) {
  const { accessToken, refreshToken, ...basicUser } = user;
  localStorage.setItem('user', JSON.stringify(basicUser));
  Cookies.set('accessToken', accessToken);
  Cookies.set('refreshToken', refreshToken);
  setAuthorizationHeader(user.accessToken);
}

export function getLocalUser(): User | null {
  const user = localStorage.getItem('user') as string;
  if (user) {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    return { ...JSON.parse(user), accessToken, refreshToken };
  }
  return null;
}

export function setTokens(accessToken: string, refreshToken: string) {
  const user = localStorage.getItem('user') as string;
  if (user) {
    setLocalUser({ ...JSON.parse(user), accessToken, refreshToken });
  }
}
