import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import i18n from '../i18n';

export function useBlocker(blocker: () => Promise<boolean>, when = true) {
  const { navigator } = useContext(NavigationContext);
  useEffect(() => {
    if (!when) {
      return;
    }
    const go = navigator.go;
    const push = navigator.push;
    const replace = navigator.replace;

    navigator.go = async (...args: Parameters<typeof go>) => (await blocker()) !== false && go(...args);

    navigator.push = async (...args: Parameters<typeof push>) => (await blocker()) !== false && push(...args);

    navigator.replace = async (...args: Parameters<typeof replace>) => (await blocker()) !== false && replace(...args);

    return () => {
      navigator.go = go;
      navigator.push = push;
      navigator.replace = replace;
    };
  }, [navigator, blocker, when]);
}
export default function usePrompt(onBeforeLeave: () => Promise<boolean>, when = true) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return i18n.t('form:abortTitle');
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  const blocker = useCallback(onBeforeLeave, [onBeforeLeave]);
  useBlocker(blocker, when);
}
