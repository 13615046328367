import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreationContext } from '../../contexts/CreationContext';
import theme from '../../style/theme';
import { CreationForm, CreationLineDetails, Domain } from '../../types';
import { getDomain } from '../../utils/helper';
import BaseDrawer from '../BaseDrawer';
import CheckboxHookForm from '../FormControls/HookForm/CheckboxHookForm';
import PriceTotal from './PriceTotal';
import EmptyProducts from './Products/EmptyProducts';
import ProductRow from './Products/ProductRow';
import SearchProducts from './Products/SearchProducts';
import SearchProductsTitle from './Products/SearchProductsTitle';
import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';

const style = {
  productsContainerWithSearch: {
    paddingBottom: '120px',
  },
  productsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: '50px',
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200],
    border: 0,
  },
  buttonBoxDrawer: {
    position: 'fixed',
    bottom: 0,
    left: '25px',
    right: '25px',
    borderRadius: '16px 16px 0 0',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
  },
  checkbox: {
    alignItems: 'flex-end',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingTop: 8,

    '& label': {
      marginRight: 0,
    }
  }
};

const CreationStep3 = () => {
  const { t } = useTranslation();
  const context = useContext(CreationContext);
  const { control } = useFormContext<CreationForm>();
  const formState = useFormState<CreationForm>();
  const { fields, remove, append } = useFieldArray<CreationForm, 'lineDetails'>({ name: 'lineDetails' });
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openedProductRows, setOpenedProductRows] = useState<string[]>([]);
  const [lastRow, setLastRow] = useState<string>(fields.length ? fields[fields.length - 1].id : '');

  const isFiretrol = getDomain() === Domain.firetrol;
  const termsLink = isFiretrol ? `${process.env.PUBLIC_URL}/firetrol_terms_and_conditions.pdf` : 'https://www.tornatech.com/wp-content/uploads/2021/05/TD-DOC-000-E-Rev.2.pdf';

  const toggleDrawer = (newOpen: boolean) => () => setDrawerOpen(newOpen);

  const toggleProductRow = useCallback((rowId: string) => {
    if (openedProductRows.includes(rowId)) {
      setOpenedProductRows(openedProductRows.filter((openedRowId) => openedRowId !== rowId))
    } else {
      setOpenedProductRows([...openedProductRows, rowId]);
    }
  }, [openedProductRows]);

  const toggleAllProductRows = useCallback(() => {
    if (openedProductRows.length === fields.length) {
      setOpenedProductRows([]);
    } else {
      setOpenedProductRows(fields.map((item) => item.id));
    }
  }, [fields, openedProductRows]);

  const renderExpandArrow = () => {
    let arrow: ReactNode;
    if (!openedProductRows.length) {
      arrow = <KeyboardArrowDown />;
    } else if (openedProductRows.length !== fields.length) {
      arrow = <KeyboardArrowRight />
    } else {
      arrow = <KeyboardArrowUp />
    }

    return (
      <IconButton size="small" onClick={() => toggleAllProductRows()}>
        {arrow}
      </IconButton>
    )
  }

  useEffect(() => {
    const fieldId = fields.length ? fields[fields.length - 1].id : '';
    if (lastRow !== fieldId) {
      setLastRow(fieldId);
      setOpenedProductRows([fieldId]);
    }
  }, [fields, lastRow])

  const onProductAdded = (product: CreationLineDetails) => {
    append(product);
    toggleDrawer(false)();
  };

  const renderHeaderTableCell = (cellContent: string | ReactNode, align?: TableCellProps['align']) => (
    <TableCell align={align}>
      {typeof cellContent === 'string'
        ? <Typography variant="paragraphSmallBold">{cellContent}</Typography>
        : cellContent
      }
    </TableCell>
  );

  const renderProductsTable = () => {
    if (fields.length === 0) {
      return <EmptyProducts />;
    }
    return (
      <Stack spacing={2}>
        <TableContainer>
          <Table>
            <TableHead sx={style.tableHead}>
              <TableRow>
                {renderHeaderTableCell(t('common:product.code'))}
                {renderHeaderTableCell(t('common:product.options'), 'center')}
                {renderHeaderTableCell(t('common:product.quantity'), 'center')}
                {!context.quotation && renderHeaderTableCell(t('form:step3.list.netBase'), 'right')}
                {!context.quotation && renderHeaderTableCell(t('form:step3.list.netOption'), 'right')}
                {renderHeaderTableCell(t('common:product.unitPrice'), 'right')}
                {renderHeaderTableCell(t('common:product.totalPrice'), 'right')}
                {renderHeaderTableCell(renderExpandArrow(), 'right')}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((line, index) => (
                <ProductRow
                  isOpen={openedProductRows.includes(line.id)}
                  toggleProductRow={() => toggleProductRow(line.id)}
                  namePrefix={`lineDetails.${index}`}
                  key={line.id}
                  line={line}
                  onDelete={() => remove(index)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <PriceTotal />
      </Stack>
    );
  };

  return (
    <Box sx={[style.productsContainer, !context.quotation && style.productsContainerWithSearch]}>
      {renderProductsTable()}
      {context.type === 'SalesOrder' && (
        <Box sx={style.checkbox}>
          <CheckboxHookForm
            control={control}
            label={
              <>
                {t('form:step3.terms.label')}{' '}
                <a href={termsLink} target="_blank">
                  {t('form:step3.terms.linkText')}
                </a>
              </>
            }
            name="termsAccepted"
          />
          <Typography variant="inputError">{formState.errors.termsAccepted?.message}</Typography>
        </Box>
      )}
      {!context.quotation && (
        <Box>
          <SearchProductsTitle isOpen={false} sx={style.buttonBoxDrawer} onClick={toggleDrawer(true)} />
          <BaseDrawer
            title={<SearchProductsTitle isOpen={true} onClick={toggleDrawer(false)} />}
            anchor="bottom"
            isOpen={isDrawerOpen}
            onClose={toggleDrawer(false)}
          >
            <SearchProducts onProductAdded={onProductAdded} />
          </BaseDrawer>
        </Box>
      )}
    </Box>
  );
};
export default CreationStep3;
