import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from '../../types';
import AlertIcon from '../Dashboard/AlertIcon';
import StatusChip from '../StatusChip';

type Props = {
  status: OrderStatus;
  alert: string;
  size?: React.ComponentProps<typeof StatusChip>['size'];
};

const SalesOrderStatus = ({ status, alert, size = 'small' }: Props) => {
  const { t } = useTranslation();

  const getStatusChipColor = () => {
    switch (status) {
      case OrderStatus.Open:
        return 'active';
      case OrderStatus.Shipped:
        return 'success';
      default:
        return 'disabled';
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <StatusChip text={t(`sales-orders:status.${status}`)} size={size} color={getStatusChipColor()} />
      {!!alert && <AlertIcon alertText={alert} size="small" />}
    </Stack>
  );
};

export default SalesOrderStatus;
