import { useState } from 'react';
import { FormCreationBase } from '../components';
import { CreationContext } from '../contexts/CreationContext';
import { Quotation } from '../types';

export function CreateSalesOrder() {
  const [quotation, setQuotation] = useState<Quotation | null>(null);

  return (
    <CreationContext.Provider value={{ type: 'SalesOrder', quotation, setQuotation }}>
      <FormCreationBase />
    </CreationContext.Provider>
  );
}
